import {
    REQUEST_GET_BLUE_FORM_LIST_DOCUMENTS,
    SUCCESS_GET_BLUE_FORM_LIST_DOCUMENTS,
    FAILURE_GET_BLUE_FORM_LIST_DOCUMENTS,
    REQUEST_UPDATE_BLUE_DATA,
    SUCCESS_UPDATE_BLUE_DATA,
    FAILURE_UPDATE_BLUE_DATA,
    // REQUEST_UPDATE_EDIT_HISTORY_DOCUMENT,
    // SUCCESS_UPDATE_EDIT_HISTORY_DOCUMENT,
    // FAILURE_UPDATE_EDIT_HISTORY_DOCUMENT,
    REQUEST_FILE_INFO,
    SUCCESS_FILE_INFO,
    FAILURE_FILE_INFO,
    REQUEST_FILE_DOWNLOAD_FORM,
    SUCCESS_FILE_DOWNLOAD_FORM,
    FAILURE_FILE_DOWNLOAD_FORM,
    // REQUEST_GET_DOCUMENT_EDIT_HISTORY,
    // SUCCESS_GET_DOCUMENT_EDIT_HISTORY,
    // FAILURE_GET_DOCUMENT_EDIT_HISTORY,
    REQUEST_GET_BLUE_FORM_SEARCH_RESULT,
    SUCCESS_GET_BLUE_FORM_SEARCH_RESULT,
    FAILURE_GET_BLUE_FORM_SEARCH_RESULT,
    SUCCESS_DELETE_BLUE_DATA,
    FAILURE_DELETE_BLUE_DATA,
    REQUEST_DELETE_BLUE_DATA,

    // UPDATE_SESSION_EXPIRY,
} from "../Constant/blueFormList";


export const requestGetBlueFormListDocuments = () => ({
    type: REQUEST_GET_BLUE_FORM_LIST_DOCUMENTS,
})
export const getBlueFormListDocumentsSuccess = (data) => ({
    type: SUCCESS_GET_BLUE_FORM_LIST_DOCUMENTS,
    payload: data,
})
export const getBlueFormListDocumentsFailure = (error) => ({
    type: FAILURE_GET_BLUE_FORM_LIST_DOCUMENTS,
    payload: error,
})

export const requestUpdateBlueData = () => ({
    type: REQUEST_UPDATE_BLUE_DATA,
})
export const updateBlueDataSuccess = (data) => ({
    type: SUCCESS_UPDATE_BLUE_DATA,
    payload: data,
})
export const updateBlueDataFailure = (error) => ({
    type: FAILURE_UPDATE_BLUE_DATA,
    payload: error,
})

// export const requestUpdateEditHistoryDocument = () => ({
//     type: REQUEST_UPDATE_EDIT_HISTORY_DOCUMENT,
// })
// export const updateEditHistoryDocumentSuccess = (data) => ({
//     type: SUCCESS_UPDATE_EDIT_HISTORY_DOCUMENT,
//     payload: data,
// })
// export const updateEditHistoryDocumentFailure = (error) => ({
//     type: FAILURE_UPDATE_EDIT_HISTORY_DOCUMENT,
//     payload: error,
// })

export const requestFileInfo = () => ({
    type: REQUEST_FILE_INFO,
})
export const fileInfoSuccess = (data) => ({
    type: SUCCESS_FILE_INFO,
    payload: data,
})
export const fileInfoFailure = (error) => ({
    type: FAILURE_FILE_INFO,
    payload: error,
})

export const requestFileDownloadForm = () => ({
    type: REQUEST_FILE_DOWNLOAD_FORM,
})
export const fileDownloadFormSuccess = (data) => ({
    type: SUCCESS_FILE_DOWNLOAD_FORM,
    payload: data,
})
export const fileDownloadFormFailure = (error) => ({
    type: FAILURE_FILE_DOWNLOAD_FORM,
    payload: error,
})

// export const requestGetDocumentEditHistory = () => ({
//     type: REQUEST_GET_DOCUMENT_EDIT_HISTORY,
// })
// export const getDocumentEditHistorySuccess = (data) => ({
//     type: SUCCESS_GET_DOCUMENT_EDIT_HISTORY,
//     payload: data,
// })
// export const getDocumentEditHistoryFailure = (error) => ({
//     type: FAILURE_GET_DOCUMENT_EDIT_HISTORY,
//     payload: error,
// })

export const requestGetBlueFormSearchResult = () => ({
    type: REQUEST_GET_BLUE_FORM_SEARCH_RESULT,
})
export const getBlueFormSearchResultSuccess = (data) => ({
    type: SUCCESS_GET_BLUE_FORM_SEARCH_RESULT,
    payload: data,
})
export const getBlueFormSearchResultFailure = (error) => ({
    type: FAILURE_GET_BLUE_FORM_SEARCH_RESULT,
    payload: error,
})

export const requestDeleteBlueData = () => ({
    type: REQUEST_DELETE_BLUE_DATA,
})
export const deleteBlueDataSuccess = (data) => ({
    type: SUCCESS_DELETE_BLUE_DATA,
    payload: data,
})
export const deleteBlueDataFailure = (error) => ({
    type: FAILURE_DELETE_BLUE_DATA,
    payload: error,
})

// export const updateSessionExpiry = (nextDay) => ({
//     type: UPDATE_SESSION_EXPIRY,
//     payload: nextDay,
// })
