import axios from "axios";
import {
    requestGetBlueFormListDocuments,
    getBlueFormListDocumentsSuccess,
    getBlueFormListDocumentsFailure,
    requestUpdateBlueData,
    updateBlueDataSuccess,
    updateBlueDataFailure,
    // requestUpdateEditHistoryDocument,
    // updateEditHistoryDocumentSuccess,
    // updateEditHistoryDocumentFailure,
    requestFileInfo,
    fileInfoSuccess,
    fileInfoFailure,
    requestFileDownloadForm,
    fileDownloadFormSuccess,
    fileDownloadFormFailure,
    // requestGetDocumentEditHistory,
    // getDocumentEditHistorySuccess,
    // getDocumentEditHistoryFailure,
    requestGetBlueFormSearchResult,
    getBlueFormSearchResultSuccess,
    getBlueFormSearchResultFailure,
    requestDeleteBlueData,
    deleteBlueDataSuccess,
    deleteBlueDataFailure,
} from "../../ActionCreator/blueFormList";

import {
    blue_data_delete_url,
    blue_data_update_url,
    file_download_form_url,
    file_info_url,
    get_blue_form_list_documents_url,
    get_blue_form_search_list_documents_url,
} from "../../../allApiPath";

export const getBlueFormListDocumentsData =
    (skip, limit, token) => async (dispatch, getState) => {
        dispatch(requestGetBlueFormListDocuments());

        axios
            .get(get_blue_form_list_documents_url(skip, limit), {
                headers: {
                    Authorization: token,
                },
            })
            .then(
                (response) => {
                    // console.log(response.data);
                    dispatch(getBlueFormListDocumentsSuccess(response.data));
                },
                (error) => {
                    dispatch(getBlueFormListDocumentsFailure(error));
                },
            );
    };

export const getFileInfo = (id) => async (dispatch, getState) => {
    dispatch(requestFileInfo());

    axios.get(file_info_url(id))
        // axios.get(get_blue_form_list_documents_url(id), {
        //     headers: {
        //         Authorization: token,
        //     },
        // })
        .then(
            (response) => {
                // console.log(response.data);
                dispatch(fileInfoSuccess(response.data));
            },
            (error) => {
                dispatch(fileInfoFailure(error));
            },
        );
};

export const processFileDownloads = async (files, dispatch) => {
    for (const file of files) {
        await dispatch(downloadFormFiles(file.id));
    }
};
const downloadFormFiles = (fileid) => (dispatch) => {
    return dispatch(downloadFormFile(fileid));
};
const downloadFormFile = (fileid) => async (dispatch) => {
    dispatch(requestFileDownloadForm());

    try {
        const downloadUrl = file_download_form_url(fileid);
        const response = await fetch(downloadUrl);
        const blob = await response.blob();

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileid;
        document.body.appendChild(link);
        link.click();

        dispatch(fileDownloadFormSuccess("Download Success"));
    } catch (error) {
        console.error("Error triggering download:", error);
        dispatch(fileDownloadFormFailure(error));
    }
};

export const blueDataUpdateSubmit =
    (jsonData, token) => async (dispatch, getState) => {
        dispatch(requestUpdateBlueData());
        // console.log(jsonData);

        axios
            .post(blue_data_update_url, jsonData, {
                headers: {
                    Authorization: token,
                },
            })
            .then(
                (response) => {
                    // console.log(response);
                    dispatch(updateBlueDataSuccess(response.data));
                },
                (error) => {
                    dispatch(updateBlueDataFailure(error));
                },
            );
    };

// export const dataUpdateEditHistorySubmit = (
//     id,
//     date,
//     currentDate,
//     selectedDistrict,
//     selectedUpazila,
//     title,
//     url,
//     selectedCategoriesOptions,
//     selectedSourceTypeOption,
//     contributorName,
//     comment,
//     death,
//     injury,
//     propertyValue,
//     noOfHouses,
//     propertyVandalized,
//     selectedReviewStatusOption,
//     token,
//     listSelectedTags,
//     listSelectedEvents,
//     dataType,
//     first_name,
//     last_name,
// ) => async (dispatch, getState) => {
//     dispatch(requestUpdateEditHistoryDocument())

//     axios.post(data_update_edit_history_url, {
//         data_type_id: id,
//         date: date,
//         review_date: currentDate,
//         location: selectedDistrict,
//         upazila: selectedUpazila,
//         title: title,
//         source_url: url,
//         categories: selectedCategoriesOptions,
//         source_type: selectedSourceTypeOption,
//         contributor_name: contributorName,
//         comment: comment,
//         death: parseInt(death),
//         injury: parseInt(injury),
//         property_value: propertyValue,
//         number_of_houses: parseInt(noOfHouses),
//         property_vandalized: parseInt(propertyVandalized),
//         review_status: selectedReviewStatusOption,
//         tags: listSelectedTags,
//         events: listSelectedEvents,
//         data_type: dataType,
//         first_name: first_name,
//         last_name: last_name,
//     }, {
//         headers: {
//             'Authorization': token,
//         },
//     })
//         .then((response) => {
//             dispatch(updateEditHistoryDocumentSuccess(response.data));
//         }, error => {
//             dispatch(updateEditHistoryDocumentFailure(error))
//         })
// }

// export const getDocumentEditHistoryData = (skip, limit, token, id) => async (dispatch, getState) => {
//     dispatch(requestGetDocumentEditHistory())

//     axios.get(get_document_edit_history_url(skip, limit, id), {
//         headers: {
//             'Authorization': token,
//         },
//     })
//         .then((response) => {
//             dispatch(getDocumentEditHistorySuccess(response.data));
//         }, error => {
//             dispatch(getDocumentEditHistoryFailure(error));
//         })
// }

export const getBlueFormSearchResult =
    (jsonData, token) => async (dispatch, getState) => {
        dispatch(requestGetBlueFormSearchResult());
        // console.log(jsonData);

        axios
            .post(get_blue_form_search_list_documents_url, jsonData, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                // body: JSON.stringify(jsonData),
            })
            .then(
                (response) => {
                    dispatch(getBlueFormSearchResultSuccess(response.data));
                    // console.log(response);
                    // console.log(response.data);
                },
                (error) => {
                    dispatch(getBlueFormSearchResultFailure(error));
                },
            );
    };

export const deleteBlueData = (id, token) => async (dispatch, getState) => {
    dispatch(requestDeleteBlueData());

    axios
        .post(
            blue_data_delete_url,
            {
                id: id,
            },
            {
                headers: {
                    Authorization: token,
                },
            },
        )
        .then(
            (response) => {
                dispatch(deleteBlueDataSuccess(response.data));
            },
            (error) => {
                dispatch(deleteBlueDataFailure(error));
            },
        );
};
