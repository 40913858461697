import { combineReducers } from "redux";

import {
    REQUEST_SIGNUP,
    SUCCESS_SIGNUP,
    FAILURE_SIGNUP,
    REQUEST_LOGIN,
    SUCCESS_LOGIN,
    FAILURE_LOGIN,
    REQUEST_LOGOUT,
    SUCCESS_LOGOUT,
    FAILURE_LOGOUT,
    REQUEST_UPDATE_PROFILE,
    SUCCESS_UPDATE_PROFILE,
    FAILURE_UPDATE_PROFILE,

    REQUEST_GET_USER_HISTORY,
    SUCCESS_GET_USER_HISTORY,
    FAILURE_GET_USER_HISTORY,

    REQUEST_UPDATE_PASSWORD,
    SUCCESS_UPDATE_PASSWORD,
    FAILURE_UPDATE_PASSWORD,

    REQUEST_USER_LIST,
    SUCCESS_UPDATE_USER_LIST,
    FAILURE_UPDATE_USER_LIST,
    REQUEST_USER_TYPE_CHANGE,
    SUCCESS_UPDATE_USER_TYPE,
    FAILURE_UPDATE_USER_TYPE,
    UPDATE_SESSION_EXPIRY,
} from "../Constant/login";

const initialState = {
    login: {
        token: "",
        user_id: 0,
        isLoggedIn: '',
        // user_type: '',
        account_type: '',
        profile: {
            first_name: "",
            last_name: "",
            user_email: "",
        },
        first_name: "",
        last_name: "",
        email: "",
        session_expiry: 0,
        // session_expiry: Date.now() + 10 * 60 * 60 * 1000, // 10 hours from now
        error: '',
    },
    isFetching: false,

    userHistory: [],
    error: "",

    updatePasswordData: {
        error: "",
        success: "",
    },
    updateMessageData: {
        error: "",
        success: "",
    }
}

const login = (state = initialState.login, action) => {
    switch (action.type) {
        case REQUEST_SIGNUP:
            return {
                ...state,
                error: '',
            }
        case SUCCESS_SIGNUP:
            return {
                ...state,
                token: action.payload.token,
                user_id: action.payload.id,
                // isLoggedIn: action.payload.isLoggedIn,
                // user_type: action.payload.user_type,
                account_type: action.payload.account_type,
                // profile: action.payload.profile,
                // session_expiry: action.payload.session_expiry,
                error: '',
            }
        case FAILURE_SIGNUP:
            return {
                ...state,
                error: action.payload,
            }

        case REQUEST_LOGIN:
            return {
                ...state,
                error: '',
            }
        case SUCCESS_LOGIN:
            return {
                ...state,
                token: action.payload.token,
                user_id: action.payload.id,
                // isLoggedIn: action.payload.isLoggedIn,
                // user_type: action.payload.user_type,
                account_type: action.payload.account_type,
                first_name: action.payload.first_name,
                last_name: action.payload.last_name,
                email: action.payload.email,
                // account_type: 'admin',
                profile: action.payload.profile,
                session_expiry: Date.now() + 10 * 60 * 60 * 1000, // 10 hours from now
                error: '',
            }
        case FAILURE_LOGIN:
            return {
                ...state,
                error: action.payload,
            }

        case REQUEST_LOGOUT:
            return {
                ...state,
                error: "",
            }
        case SUCCESS_LOGOUT:
            return {
                ...state,
                token: "",
                user_id: '',
                isLoggedIn: '',
                // user_type: '',
                account_type: '',
                profile: {},
                session_expiry: 0,
                error: "",
            }
        case FAILURE_LOGOUT:
            return {
                ...state,
                error: action.payload,
            }

        case REQUEST_UPDATE_PROFILE:
            return {
                ...state,
                error: '',
            }
        case SUCCESS_UPDATE_PROFILE:
            return {
                ...state,
                profile: action.payload.profile,
            }
        case FAILURE_UPDATE_PROFILE:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_SESSION_EXPIRY:
            return {
                ...state,
                session_expiry: action.payload,
            }
        default:
            return state;
    }
};

const isFetching = (state = initialState.isFetching, action) => {
    switch (action.type) {
        // case REQUEST_FETCH_ID:
        // case REQUEST_FETCH_PROFILE:
        case REQUEST_SIGNUP:
        case REQUEST_LOGIN:
        case REQUEST_LOGOUT:
        case REQUEST_UPDATE_PROFILE:
        case REQUEST_UPDATE_PASSWORD:
            return true;

        // case SUCCESS_FETCH_ID:
        // case SUCCESS_FETCH_PROFILE:
        // case FAILURE_FETCH_ID:
        // case FAILURE_FETCH_PROFILE:
        case SUCCESS_SIGNUP:
        case SUCCESS_LOGIN:
        case SUCCESS_LOGOUT:
        case SUCCESS_UPDATE_PROFILE:
        case SUCCESS_UPDATE_PASSWORD:
        case FAILURE_SIGNUP:
        case FAILURE_LOGIN:
        case FAILURE_LOGOUT:
        case FAILURE_UPDATE_PROFILE:
        case FAILURE_UPDATE_PASSWORD:
            return false;

        default:
            return state;
    }
};

const listUserData = (state = initialState, action) => {
    switch (action.type) {

        case REQUEST_USER_LIST:
            return {
                ...state,
                listUser: [],
                error: '',
            }
        case SUCCESS_UPDATE_USER_LIST:
            return {
                ...state,
                listUser: action.payload,
                error: '',
            }
        case FAILURE_UPDATE_USER_LIST:
            return {
                ...state,
                listUser: [],
                error: action.payload,
            }

        default:
            return state;
    }
};

const userHistoryData = (state = initialState, action) => {
    switch (action.type) {

        case REQUEST_GET_USER_HISTORY:
            return {
                ...state,
                userHistory: [],
                error: '',
            }
        case SUCCESS_GET_USER_HISTORY:
            return {
                ...state,
                userHistory: action.payload,
                error: '',
            }
        case FAILURE_GET_USER_HISTORY:
            return {
                ...state,
                userHistory: [],
                error: action.payload,
            }

        default:
            return state;
    }
};

const updatePasswordData = (state = initialState.updatePasswordData, action) => {
    switch (action.type) {

        case REQUEST_UPDATE_PASSWORD:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_UPDATE_PASSWORD:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_UPDATE_PASSWORD:
            return {
                ...state,
                success: '',
                error: action.payload,
            }


        default:
            return state;
    }
};

const updateMessageData = (state = initialState.updateMessageData, action) => {
    switch (action.type) {

        case REQUEST_USER_TYPE_CHANGE:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_UPDATE_USER_TYPE:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_UPDATE_USER_TYPE:
            return {
                ...state,
                success: '',
                error: action.payload.message,
            }


        default:
            return state;
    }
};

export default combineReducers({
    login,
    isFetching,
    listUserData,
    userHistoryData,
    updatePasswordData,
    updateMessageData,
});

// export const selectLoginIsFetching = state => state.isFetching;
// export const selectUserName = state => state.login.userName || '';
export const selectExpiryTime = state => state.login.session_expiry;
// console.log(initialState.login.session_expiry);
// console.log(selectExpiryTime);

// export const selectProfile = state => (state.login.profile && {
//     ...state.login.profile,
//     address: Array.isArray(state.login.profile.address) ? state.login.profile.address.join(', ') : ''
// }) || {};


export const selectUserID = state => state.login.user_id || '';
export const selectToken = state => state.login.token || '';
export const selectIsLoggedIn = state => (state.login.token !== '' && state.login.user_id !== '') ? true : false;

export const selectIsAdmin = function (state) {
    if (state.login.account_type === 'admin' || state.login.account_type === 'super-admin') return true;
    return false;
}

export const selectIsSuperAdmin = function (state) {
    if (state.login.account_type === 'super-admin') return true;
    return false;
}

// console.log(selectIsAdmin);
export const selectProfile = state => state.login.profile;
export const selectUserLoginErrorMessage = state => state.login.error;

export const selectUserHistory = state => state.userHistoryData.userHistory || [];
export const selectUpdatePassword = state => state.updatePasswordData;

export const selectUserList = state => state.listUserData || [];

export const selectUpdateMessage = state => state.updateMessageData;

export const selectUserName = state => {
    return {
        "first_name": state.login.first_name,
        "last_name": state.login.last_name,
        "email": state.login.email,
    }
}


