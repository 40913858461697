import { Box, Image } from "@chakra-ui/react";
import { useState, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf';

import { pdfjs } from 'react-pdf';
// import { AspectRatio } from "@chakra-ui/react";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`;

function PDF({ src }) {
	const container = useRef(null);

	const [width, setWidth] = useState(0);

	useEffect(() => {
		setWidth(container.current.clientWidth);
	}, []);

	const [numPages, setNumPages] = useState(0);

	return (
		<>
			<div ref={container}>
				<Document
					file={src}
					onLoadSuccess={({ numPages }) => setNumPages(numPages)}
					onLoadError={console.error}
				>
					{Array.from(new Array(numPages)).map((_, i) => (
						<Page
							pageNumber={i + 1}
							width={width}
							key={i}
							renderTextLayer={false}
							renderAnnotationLayer={false}
							scale={1}
						/>
					))}
				</Document>
			</div>
		</>
	);
}

const AttachmentPreview = ({ fileUrl, fileType }) => {
	// console.log(fileUrl);
	// console.log(fileType);

	const renderPreview = () => {
		if (fileType === "pdf") {
			return (
				<Box w="100%" p={0}>
					<PDF src={fileUrl} />
				</Box>
			)
		} else if (["jpg", "jpeg", "png", "gif", "bmp", "tiff", "svg"].includes(fileType)) {
			return (
				<Image
					src={fileUrl}
					alt="Image Preview"
					objectFit="cover"
					// w="100%"
					w="auto"
					// h="100%"
					maxH={window.innerHeight * 0.9}
				/>
			);
		} else if (["mp4", "mov", "webm", "ogg", "avi", "mkv"].includes(fileType)) {
			return (
				<video
					controls
					// style={{ width: "100%", height: 'auto', padding: 0 }}
					style={{ width: "100%", maxHeight: (window.innerHeight * 0.9), padding: 0 }}
					overflow='hidden'
					p={0}
				>
					{fileType === "mp4" && <source src={fileUrl} display="block" type="video/mp4" />}
					{fileType === "mov" && <source src={fileUrl} display="block" type="video/quicktime" />}
					{fileType === "webm" && <source src={fileUrl} display="block" type="video/webm" />}
					{fileType === "ogg" && <source src={fileUrl} display="block" type="video/ogg" />}
					{fileType === "avi" && <source src={fileUrl} display="block" type="video/x-msvideo" />}
					{fileType === "mkv" && <source src={fileUrl} display="block" type="video/x-matroska" />}

					Your browser does not support the video tag.
				</video>
			);
		} else if (["mp3", "wav", "aac"].includes(fileType)) {
			return (
				<audio controls style={{ width: "100%" }}>
					<source src={fileUrl} type={`audio/${fileType}`} />
					Your browser does not support the audio tag.
				</audio>
			);
		} else if (["txt", "json", "csv"].includes(fileType)) {
			fetch(fileUrl).then(response => response.text()).then(text => (
				<pre>{text}</pre>
			));
		} else {
			return <Box textAlign="center" p={4}>Unsupported file format.</Box>;
		}
	};

	return (
		<Box p={0} w="100%" mx="auto">
			{/* Attachment preview area */}
			<Box
				w={'100%'}
				backgroundColor="transparent"
				border="1px solid #e2e8f0"
				borderRadius="md"
				overflow="hidden"
				bg="gray.100"
				display="flex"
				justifyContent="center"
				alignItems="center"
				mx="auto"
				position="relative"
				p={0}
			>
				{/* Render preview based on file type */}
				{renderPreview()}
			</Box>
		</Box>
	);
};

export default AttachmentPreview;