import { combineReducers } from "redux";
import {
    REQUEST_GET_BLUE_FORM_LIST_DOCUMENTS,
    SUCCESS_GET_BLUE_FORM_LIST_DOCUMENTS,
    FAILURE_GET_BLUE_FORM_LIST_DOCUMENTS,
    REQUEST_GET_BLUE_FORM_SEARCH_RESULT,
    SUCCESS_GET_BLUE_FORM_SEARCH_RESULT,
    FAILURE_GET_BLUE_FORM_SEARCH_RESULT,
    REQUEST_UPDATE_BLUE_DATA,
    SUCCESS_UPDATE_BLUE_DATA,
    FAILURE_UPDATE_BLUE_DATA,
    // REQUEST_UPDATE_EDIT_HISTORY_DOCUMENT,
    // SUCCESS_UPDATE_EDIT_HISTORY_DOCUMENT,
    // FAILURE_UPDATE_EDIT_HISTORY_DOCUMENT,
    REQUEST_FILE_INFO,
    SUCCESS_FILE_INFO,
    FAILURE_FILE_INFO,
    REQUEST_FILE_DOWNLOAD_FORM,
    SUCCESS_FILE_DOWNLOAD_FORM,
    FAILURE_FILE_DOWNLOAD_FORM,
    // REQUEST_GET_DOCUMENT_EDIT_HISTORY,
    // SUCCESS_GET_DOCUMENT_EDIT_HISTORY,
    // FAILURE_GET_DOCUMENT_EDIT_HISTORY,
    SUCCESS_DELETE_BLUE_DATA,
    FAILURE_DELETE_BLUE_DATA,
    REQUEST_DELETE_BLUE_DATA,
} from "../Constant/blueFormList";

const initialState = {
    blueFormListDocuments: [],
    error: "",
    loading: false,
    fileInfo: {
        success: '',
        ivpfile_id: "",
        file_name: "",
        mime_type: '',
        error: "",
    },
    fileDownloadForm: {
        success: '',
        error: "",
    },
    blueDataUpdate: {
        success: '',
        error: "",
    },
    // dataUpdateEditHistory: {
    //     success: '',
    //     error: "",
    // },
    blueDatasAction: {
        success: '',
        error: "",
    },
    // documentEditHistory: [],
    isFetching: false,
}

const blueFormListDocumentsData = (state = initialState, action) => {
    switch (action.type) {

        case REQUEST_GET_BLUE_FORM_LIST_DOCUMENTS:
        case REQUEST_GET_BLUE_FORM_SEARCH_RESULT:
            return {
                ...state,
                blueFormListDocuments: [],
                error: '',
            }
        case SUCCESS_GET_BLUE_FORM_LIST_DOCUMENTS:
        case SUCCESS_GET_BLUE_FORM_SEARCH_RESULT:
            return {
                ...state,
                blueFormListDocuments: action.payload,
                error: '',
            }
        case FAILURE_GET_BLUE_FORM_LIST_DOCUMENTS:
        case FAILURE_GET_BLUE_FORM_SEARCH_RESULT:
            return {
                ...state,
                blueFormListDocuments: [],
                error: action.payload,
            }

        default:
            return state;
    }
};

const infoFile = (state = initialState.fileInfo, action) => {
    switch (action.type) {

        case REQUEST_FILE_INFO:
            return {
                ...state,
                success: '',
                ivpfile_id: "",
                file_name: "",
                mime_type: '',
                error: "",
            }
        case SUCCESS_FILE_INFO:
            return {
                ...state,
                // success: action.payload.message,
                success: action.payload.message,
                ivpfile_id: action.payload.ivpfile_id,
                file_name: action.payload.file_name,
                mime_type: action.payload.mime_type,
                error: "",
            }
        case FAILURE_FILE_INFO:
            return {
                ...state,
                success: '',
                ivpfile_id: "",
                file_name: "",
                mime_type: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

const downloadFormFile = (state = initialState.fileDownloadForm, action) => {
    switch (action.type) {

        case REQUEST_FILE_DOWNLOAD_FORM:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_FILE_DOWNLOAD_FORM:
            return {
                ...state,
                // success: action.payload.message,
                success: action.payload,
                error: '',
            }
        case FAILURE_FILE_DOWNLOAD_FORM:
            return {
                ...state,
                success: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

const updateBlueData = (state = initialState.blueDataUpdate, action) => {
    switch (action.type) {

        case REQUEST_UPDATE_BLUE_DATA:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_UPDATE_BLUE_DATA:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_UPDATE_BLUE_DATA:
            return {
                ...state,
                success: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

// const updateEditHistoryData = (state = initialState.dataUpdateEditHistory, action) => {
//     switch (action.type) {

//         case REQUEST_UPDATE_EDIT_HISTORY_DOCUMENT:
//             return {
//                 ...state,
//                 success: '',
//                 error: '',
//             }
//         case SUCCESS_UPDATE_EDIT_HISTORY_DOCUMENT:
//             return {
//                 ...state,
//                 success: action.payload.message,
//                 error: '',
//             }
//         case FAILURE_UPDATE_EDIT_HISTORY_DOCUMENT:
//             return {
//                 ...state,
//                 success: '',
//                 error: action.payload,
//             }

//         default:
//             return state;
//     }
// };

// const documentEditHistoryData = (state = initialState, action) => {
//     switch (action.type) {

//         case REQUEST_GET_DOCUMENT_EDIT_HISTORY:
//             return {
//                 ...state,
//                 documentEditHistory: [],
//                 error: '',
//             }
//         case SUCCESS_GET_DOCUMENT_EDIT_HISTORY:
//             return {
//                 ...state,
//                 documentEditHistory: action.payload,
//                 error: '',
//             }
//         case FAILURE_GET_DOCUMENT_EDIT_HISTORY:
//             return {
//                 ...state,
//                 documentEditHistory: [],
//                 error: action.payload,
//             }

//         default:
//             return state;
//     }
// };

const actionBlueDatas = (state = initialState.blueDatasAction, action) => {
    switch (action.type) {

        case REQUEST_DELETE_BLUE_DATA:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_DELETE_BLUE_DATA:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_DELETE_BLUE_DATA:
            return {
                ...state,
                success: '',
                error: action.payload,
            }

        default:
            return state;
    }
};

const isFetching = (state = initialState.isFetching, action) => {
    switch (action.type) {
        case REQUEST_GET_BLUE_FORM_LIST_DOCUMENTS:
        case REQUEST_GET_BLUE_FORM_SEARCH_RESULT:
        case REQUEST_DELETE_BLUE_DATA:
        case REQUEST_UPDATE_BLUE_DATA:
        case REQUEST_FILE_DOWNLOAD_FORM:
        case REQUEST_FILE_INFO:
            // case REQUEST_UPDATE_EDIT_HISTORY_DOCUMENT:
            // case REQUEST_GET_DOCUMENT_EDIT_HISTORY:
            return true;

        case SUCCESS_GET_BLUE_FORM_LIST_DOCUMENTS:
        case SUCCESS_GET_BLUE_FORM_SEARCH_RESULT:
        case SUCCESS_DELETE_BLUE_DATA:
        case SUCCESS_UPDATE_BLUE_DATA:
        case SUCCESS_FILE_DOWNLOAD_FORM:
        case SUCCESS_FILE_INFO:
        case FAILURE_GET_BLUE_FORM_LIST_DOCUMENTS:
        case FAILURE_GET_BLUE_FORM_SEARCH_RESULT:
        case FAILURE_DELETE_BLUE_DATA:
        case FAILURE_UPDATE_BLUE_DATA:
        case FAILURE_FILE_DOWNLOAD_FORM:
        case FAILURE_FILE_INFO:
            // case SUCCESS_UPDATE_EDIT_HISTORY_DOCUMENT:
            // case SUCCESS_GET_DOCUMENT_EDIT_HISTORY:
            // case FAILURE_UPDATE_EDIT_HISTORY_DOCUMENT:
            // case FAILURE_GET_DOCUMENT_EDIT_HISTORY:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    blueFormListDocumentsData,
    infoFile,
    downloadFormFile,
    updateBlueData,
    // updateEditHistoryData,
    // documentEditHistoryData,
    actionBlueDatas,
    isFetching,
});

export const selectBlueFormListDocuments = state => state;
export const selectUpdateBlueData = state => state.updateBlueData;
// export const selectUpdateEditHistoryData = state => state.updateEditHistoryData;
export const selectInfoFile = state => state.infoFile;
export const selectDownloadFormFile = state => state.downloadFormFile;
// export const selectDocumentEditHistory = state => state;
export const selectActionBlueDatas = state => state.actionBlueDatas;


// export const selectLine = state => state.lines;
// export const selectNewLine = state => state.lines;
// export const selectTranslatedLine = state => state.lines;
