import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	IconButton,
	Text,
	HStack,
} from "@chakra-ui/react"
// import { Search2Icon, DownloadIcon, ViewIcon, EditIcon, RepeatIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
// import { FaDownload } from 'react-icons/fa';
import EditHistoryModal from "../../Components/EditHistoryModal";
import FilePreviewModal from '../../Components/FilePreviewModal';
import EditModal from '../../Components/EditModal';
import { useDisclosure, } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import toast from 'react-hot-toast';
// import { FaCheck, FaClock, FaExclamationCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';


import { selectDocumentEditHistory, selectDownloadFile, selectListDocuments, selectToken, selectUpdateData, selectUpdateEditHistoryData, selectUserName } from '../../Redux/Reducer';
import { dataUpdateEditHistorySubmit, dataUpdateSubmit, downloadFile, getDocumentEditHistoryData, getListDocumentsData, getSearchResult } from '../../Redux/Thunk/ListDocuments';
// Import necessary Chakra UI components and react-icons
//import { Badge, Icon } from '@chakra-ui/react'; // Add Icon here
import Layout from "../../Layout/Layout";
import About from "./About/index";

import SearchOptions from "./SearchOptions";
import LibraryResources from "./LibraryResources";
import districtUpazilaData from '../../Components/districtUpazilaData';

export default function LibraryNew() {

	// const { innerHeight } = window;
	const dispatch = useDispatch();

	// Decode the URL to get the readable Bangla text
	const decode = (url) => {
		const decodedUrl = decodeURIComponent(url);
		return decodedUrl;
	}

	const token = useSelector(state => selectToken(state));
	const data = useSelector(state => selectListDocuments(state));
	const update = useSelector(state => selectUpdateData(state));
	const downloadMessage = useSelector(state => selectDownloadFile(state));
	const documentEditHistory = useSelector(state => selectDocumentEditHistory(state));
	const userName = useSelector(state => selectUserName(state));

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(50);  // Default to 5 items per page

	const [processed, setProcessed] = useState("unprocessed");

	const [curFilename, setFileName] = useState("");
	const [curFileType, setFileType] = useState("");

	// initial data load and after recall
	useEffect(() => {
		dispatch(getListDocumentsData((currentPage - 1) * itemsPerPage, itemsPerPage, token, processed));
	}, [dispatch, token, currentPage, itemsPerPage, processed]);

	const [tableData, setTableData] = useState([]);

	// data set 
	useEffect(() => {
		if (data && data.listDocuments) {
			setTableData(data.listDocuments);
		}
	}, [data]);


	// Modal for editing
	const [id, setId] = useState('');
	const [downloadID, setDownloadID] = useState('');

	const [date, setDate] = useState('');
	const handleDateChange = (e) => {
		setDate(e.target.value);
		// setDateUpdated(true);
	};

	const [reviewDate, setReviewDate] = useState('');
	// const [dateUpdated, setDateUpdated] = useState(false);
	const getCurrentDate = () => {
		const today = new Date();
		const year = today.getFullYear();
		const month = String(today.getMonth() + 1).padStart(2, '0'); // Add 1 because months are 0-indexed
		const day = String(today.getDate()).padStart(2, '0');

		return `${year}-${month}-${day}`;
	};
	const currentDate = getCurrentDate();
	// const handleReviewDateChange = (e) => {
	//     setReviewDate(e.target.value);
	//     setDateUpdated(true);
	// };


	const [selectedDistrict, setSelectedDistrict] = useState('');
	const [upazilas, setUpazilas] = useState([]);
	const [selectedUpazila, setSelectedUpazila] = useState('');
	const handleDistrictChange = (event) => {
		const district = event.target.value;
		setSelectedDistrict(district);
		setUpazilas(districtUpazilaData[district] || []);
		setSelectedUpazila(''); // Reset upazila when district changes
	};
	const handleUpazilaChange = (event) => {
		const upazila = event.target.value;
		setSelectedUpazila(upazila);
	};

	const [title, setTitle] = useState('');
	const handleTitleChange = (e) => setTitle(e.target.value);

	const [url, setURL] = useState('');
	const handleURLChange = (e) => setURL(decode(e.target.value));

	const [selectedCategoriesOptions, setSelectedCategoriesOptions] = useState([]);
	const [categoriesOptions, setCategoriesOptions] = useState([
		{ value: 'হিন্দু নির্যাতন', label: 'হিন্দু নির্যাতন' },
		{ value: 'ঘরবাড়ি ও কারখানা লুটপাট(আইন শৃঙ্খলা পরিস্থিতি অবনতি)', label: 'ঘরবাড়ি ও কারখানা লুটপাট(আইন শৃঙ্খলা পরিস্থিতি অবনতি)' },
		{ value: 'সাংবাদিক নির্যাতন', label: 'সাংবাদিক নির্যাতন' },
		{ value: 'শিক্ষক নির্যাতন', label: 'শিক্ষক নির্যাতন' },
		{ value: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনী কর্তৃক অত্যাচার', label: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনী কর্তৃক অত্যাচার' },
		{ value: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনীর উপরে ঘটে যাওয়া নৃশংসতা', label: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনীর উপরে ঘটে যাওয়া নৃশংসতা' },
		{ value: 'উসকানীমূলক বক্তব্য', label: 'উসকানীমূলক বক্তব্য' },
		{ value: 'প্রকাশ্য জঙ্গীবাদী তৎপরতা', label: 'প্রকাশ্য জঙ্গীবাদী তৎপরতা' },
		{ value: 'প্রকাশ্য সাম্প্রদায়িক তৎপরতা', label: 'প্রকাশ্য সাম্প্রদায়িক তৎপরতা' },
		{ value: 'অগনতান্ত্রিক আচরন', label: 'অগনতান্ত্রিক আচরন' },
		{ value: 'বিচার বিভাগীয় ব্যর্থতা', label: 'বিচার বিভাগীয় ব্যর্থতা' },
		{ value: '১৯৭১ সাল  সম্পর্কিত যেকোন ‌অপপ্রচার অথবা হামলা', label: '১৯৭১ সাল  সম্পর্কিত যেকোন ‌অপপ্রচার অথবা হামলা' },
		{ value: 'সাংস্কৃতিক আইকন, বা মূর্তি সম্পর্কিত যেকোন হামলা', label: 'সাংস্কৃতিক আইকন, বা মূর্তি সম্পর্কিত যেকোন হামলা' },
		{ value: 'Other', label: 'Other' },
	]);

	const handleCheckboxChange = (value) => {
		setSelectedCategoriesOptions((prev) =>
			prev.includes(value)
				? prev.filter((item) => item !== value)
				: [...prev, value]
		);
	};

	const [selectedSourceTypeOption, setSelectedSourceTypeOption] = useState('');
	const [options, setOptions] = useState([
		{ value: 'webpage', label: 'Webpage' },
		{ value: 'facebook', label: 'Facebook Video' },
		{ value: 'youtube', label: 'YouTube Video' },
	]);
	const handleSourceTypeChange = (event) => {
		setSelectedSourceTypeOption(event.target.value);
	};

	const [contributorName, setContributorName] = useState('');
	const handleContributorNameChange = (e) => setContributorName(e.target.value);

	const [comment, setComment] = useState('');
	const handleCommentChange = (e) => setComment(e.target.value);

	const [death, setDeath] = useState('');
	const handleDeathChange = (e) => setDeath(e.target.value);

	const [injury, setInjury] = useState('');
	const handleInjuryChange = (e) => setInjury(e.target.value);

	const [propertyValue, setPropertyValue] = useState('');
	const handlePropertyValueChange = (e) => setPropertyValue(e.target.value);

	const [noOfHouses, setNoOfHouses] = useState('');
	const handleNoOfHousesChange = (e) => setNoOfHouses(e.target.value);

	const [propertyVandalized, setPropertyVandalized] = useState('');
	const handlePropertyVandalizedChange = (e) => setPropertyVandalized(e.target.value);

	const [selectedReviewStatusOption, setSelectedReviewStatusOption] = useState('');
	const [optionsReviewStatus, setOptionsReviewStatus] = useState([
		{ value: 'clear', label: 'Clear' },
		{ value: 'duplicate', label: 'Duplicate' },
		{ value: 'need_more_documents', label: 'Need More Documents' },
		{ value: 'garbage', label: 'Garbage' },
	]);
	const handleReviewStatusChange = (event) => {
		setSelectedReviewStatusOption(event.target.value);
	};

	const [listSelectedTags, listSetSelectedTags] = useState([]);
	const [listSelectedEvents, listSetSelectedEvents] = useState([]);

	const dataType = 'link';

	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleEdit = (doc) => {
		setId('');
		setDate('');
		setReviewDate('');
		// setDateUpdated(false);
		setSelectedDistrict('');
		setUpazilas([]);
		setSelectedUpazila('');
		setContributorName('');
		setComment('');
		setTitle('');
		setURL('');
		setSelectedCategoriesOptions([]);
		setSelectedSourceTypeOption('');
		setDeath('');
		setInjury('');
		setPropertyValue('');
		setNoOfHouses('');
		setPropertyVandalized('');
		setSelectedReviewStatusOption("");
		listSetSelectedTags([]);
		listSetSelectedEvents([]);
		setDownloadID('');

		setId(doc.id);
		setDate(doc.date);
		setReviewDate(doc.review_date);
		setSelectedDistrict(doc.location);
		setUpazilas(districtUpazilaData[doc.location] || []);
		setSelectedUpazila(doc.upazila);
		setContributorName(doc.contributor_name);
		setComment(doc.comment);
		setTitle(doc?.title);
		setURL(decode(doc?.source_url));
		setSelectedCategoriesOptions(doc.categories);
		setSelectedSourceTypeOption(doc.description);
		setDeath(doc.death);
		setInjury(doc.injury);
		setPropertyValue(doc.property_value);
		setNoOfHouses(doc.number_of_houses);
		setPropertyVandalized(doc.property_vandalized);
		setSelectedReviewStatusOption(doc.review_status);
		listSetSelectedTags(doc.tags || []);
		listSetSelectedEvents(doc.events || []);
		setDownloadID(doc.download_id);

		onOpen();  // Open the modal
	};
	// data update
	const handleSubmit = event => {
		event.preventDefault();

		dispatch(dataUpdateSubmit(
			id,
			date,
			currentDate, // reviewDate
			selectedDistrict,
			selectedUpazila,
			title,
			url,
			selectedCategoriesOptions,
			selectedSourceTypeOption,
			contributorName,
			comment,
			death,
			injury,
			propertyValue,
			noOfHouses,
			propertyVandalized,
			selectedReviewStatusOption,
			token,
			listSelectedTags,
			listSelectedEvents,
			false, // download_again
			downloadID
		));

		dispatch(dataUpdateEditHistorySubmit(
			id,
			date,
			currentDate, // reviewDate
			selectedDistrict,
			selectedUpazila,
			title,
			url,
			selectedCategoriesOptions,
			selectedSourceTypeOption,
			contributorName,
			comment,
			death,
			injury,
			propertyValue,
			noOfHouses,
			propertyVandalized,
			selectedReviewStatusOption,
			token,
			listSelectedTags,
			listSelectedEvents,
			dataType,
			userName.first_name,
			userName.last_name,
		));

		onClose();
	};
	// data resync 
	const handleResyncSubmit = () => {

		dispatch(dataUpdateSubmit(
			previewFile?.id,
			'',
			'',
			'',
			'',
			'',
			previewFile?.source_url,
			[],
			previewFile?.source_type ?? previewFile?.description,
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			token,
			[],
			[],
			true,// download_again
			previewFile?.download_id,
		));

		// console.log(previewFile);
		onFileViewClose();

		toast.success('Your pdf will be resynced soon.', { duration: 5000, });
	};
	// toast 
	useEffect(() => {
		if (update.success) {
			toast.success(update.success);
			dispatch(getListDocumentsData((currentPage - 1) * itemsPerPage, itemsPerPage, token, processed));
		}
		if (update.error.message) {
			toast.error(update.error.message);
		}
	}, [update.error.message, update.success, dispatch, onClose, currentPage, itemsPerPage, token, processed]);

	// edit history
	const { isOpen: isEditHistoryOpen, onOpen: onEditHistoryOpen, onClose: onEditHistoryClose } = useDisclosure();

	const handleEditHistory = (id) => {
		// console.log(id);
		dispatch(getDocumentEditHistoryData(0, 5, token, id));

		onEditHistoryOpen();  // Open the modal
	};

	// file preview
	const [isFileViewOpen, setFileViewOpen] = useState(false);
	const [previewFile, setPreviewFile] = useState([]);
	// console.log(previewFile);
	const onFileViewClose = () => {
		setFileViewOpen(false);
	}
	const handlePreview = (file) => {
		setPreviewFile([]);

		if (file.filename) {
			setFileName(file.filename);
			setPreviewFile(file);

			const fileExtension = file.filename.substring(file.filename.lastIndexOf('.') + 1);

			setFileType(fileExtension);

			setFileViewOpen(true);
		}
	};


	// DOWNLOAD single file
	const handleDownload = (filename) => {
		if (filename) {
			toast.success("Your download request has been received and will be delivered soon.");
			dispatch(downloadFile(filename, token));
		}
	};
	// download all file from list
	const handleAllDownload = () => {
		toast.success("Your download request of multiple file has been received and will be delivered soon.");
		filteredData.map((td, i) => (
			dispatch(downloadFile(td.filename, token))
		))
	};
	// download all file from select
	const [selectFlag, setSelectFlag] = useState(false);
	const [downloadButtonText, setDownloadButtonText] = useState('Download All Listed File');
	const [selectButtonText, setSelectButtonText] = useState('Select Items');
	const handleSelectClick = (e) => {
		setSelectFlag(!e);
		setDownloadButtonText(e ? 'Download All Listed File' : 'Download All Selected File')
		setSelectButtonText(e ? 'Select Items' : 'Unselect')
		if (e) {
			setSelectFile([]);
		}
	}
	const handleSelectAll = () => {
		setSelectFile([]);
		filteredData.map((td, i) => (
			setSelectFile((prev) => [...prev, td.filename])
		));
	}
	const [selectedFile, setSelectFile] = useState([]);
	const handleSelectFileDownload = () => {
		if (selectedFile.length > 0) {
			toast.success("Your download request of multiple files has been received and will be delivered soon.");
			selectedFile.map((filename, i) => (
				dispatch(downloadFile(filename, token))
			))
		}
		else {
			toast.error("Please select at least one file to download");
		}
	};
	// file download number 
	const [downloadFileNo, setDownloadFileNo] = useState(0);
	useEffect(() => {
		if (selectFlag && selectedFile.length >= 0) {
			setDownloadFileNo(selectedFile.length);
		}
		else {
			setDownloadFileNo(tableData.length);
		}
	}, [selectFlag, selectedFile.length, tableData.length]);
	// toast 
	useEffect(() => {
		if (downloadMessage.success) {
			toast.success(downloadMessage.success);
		}
		if (downloadMessage.error.message) {
			toast.error(downloadMessage.error.message);
		}
	}, [downloadMessage.error.message, downloadMessage.success]);

	// for search
	const [selectedSearch, setSelectedSearch] = useState('');
	const [selectedSearchOptions, setSelectedSearchOptions] = useState([]);
	// Toggle option selection
	const handleOptionToggle = (value) => {
		setSelectedSearchOptions((prevSelected) =>
			prevSelected.includes(value)
				? prevSelected.filter((item) => item !== value)
				: [...prevSelected, value]
		);
	};
	// if filtering inside table data
	// const filteredData = tableData.filter((item) => {
	// 	const matchesCategory = selectedSearchOption
	// 		? item.categories.some(category => category.toLowerCase() === selectedSearchOption.toLowerCase())
	// 		: true;

	// 	const matchesSearch = selectedSearch
	// 		? item.description.toLowerCase().includes(selectedSearch.toLowerCase())
	// 		: true;

	// 	return matchesCategory && matchesSearch;
	// });
	const filteredData = tableData;
	//  {/* tag/events/date search system  */}
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [listSearchSelectedTags, setListSearchSelectedTags] = useState([]);
	const [listSearchSelectedEvents, setListSearchSelectedEvents] = useState([]);
	const handleSearch = () => {
		// dispatch(getSearchResult((currentPage - 1) * itemsPerPage, itemsPerPage, token, startDate, endDate, listSearchSelectedTags, listSearchSelectedEvents));
		dispatch(getSearchResult(1, 50, token, startDate, endDate, selectedSearch, selectedSearchOptions, listSearchSelectedTags, listSearchSelectedEvents));

		// setCurrentPage(1);
		// setItemsPerPage(50);
		setSelectedSearch("");
		setSelectedSearchOptions([]);
		setStartDate("");
		setEndDate("");
		setListSearchSelectedTags([]);
		setListSearchSelectedEvents([]);
	};

	// pagination
	const handlePageChange = (page) => {
		if (page >= 1) {
			setCurrentPage(page);
		}
	};


	return (
		<Layout>
			<Box minH="100vh" bg="gray.50" fontFamily="Lato, sans-serif">
				{/* Main Content */}
				<Box maxW="container.xl" mx="auto" px={4} py={8}>
					<Text fontSize="4xl" fontWeight="bold" color="gray.800" mb={6} fontFamily="Montserrat, sans-serif">
						Conflict Research Library
					</Text>

					<Box bg="white" shadow="md" rounded="lg" p={6} mb={8}>

						{/* Search Resources Section */}
						<SearchOptions
							handleOptionToggle={handleOptionToggle}
							selectedSearchOptions={selectedSearchOptions}
							categoriesOptions={categoriesOptions}
							setSelectedSearch={setSelectedSearch}
							selectedSearch={selectedSearch}
							setProcessed={setProcessed}
							handleAllDownload={handleAllDownload}
							handleSelectFileDownload={handleSelectFileDownload}
							handleSelectAll={handleSelectAll}
							handleSelectClick={handleSelectClick}
							filteredData={filteredData}
							downloadFileNo={downloadFileNo}
							selectFlag={selectFlag}
							downloadButtonText={downloadButtonText}
							selectButtonText={selectButtonText}
							listSearchSelectedTags={listSearchSelectedTags}
							setListSearchSelectedTags={setListSearchSelectedTags}
							listSearchSelectedEvents={listSearchSelectedEvents}
							setListSearchSelectedEvents={setListSearchSelectedEvents}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
							handleSearch={handleSearch}
						/>

						<Text fontSize="2xl" fontWeight="semibold" color="gray.800" mb={4} fontFamily="Montserrat, sans-serif">
							Recent Uploads
						</Text>

						{/* Featured Resources and Recent Uploads */}
						<LibraryResources
							filteredData={filteredData}
							decode={decode}
							handleDownload={handleDownload}
							handlePreview={handlePreview}
							selectFlag={selectFlag}
							selectedFile={selectedFile}
							setSelectFile={setSelectFile}
							handleEdit={handleEdit}
							handleEditHistory={handleEditHistory}
						/>
					</Box>

					{/* Modal for editing document */}
					<EditModal
						isOpen={isOpen}
						onClose={onClose}
						date={date}
						handleDateChange={handleDateChange}
						reviewDate={reviewDate}
						// handleReviewDateChange={handleReviewDateChange}
						selectedDistrict={selectedDistrict}
						handleDistrictChange={handleDistrictChange}
						upazilas={upazilas}
						selectedUpazila={selectedUpazila}
						handleUpazilaChange={handleUpazilaChange}
						title={title}
						handleTitleChange={handleTitleChange}
						url={url}
						handleURLChange={handleURLChange}
						categoriesOptions={categoriesOptions}
						selectedCategoriesOptions={selectedCategoriesOptions}
						handleCheckboxChange={handleCheckboxChange}
						handleSourceTypeChange={handleSourceTypeChange}
						selectedSourceTypeOption={selectedSourceTypeOption}
						contributorName={contributorName}
						handleContributorNameChange={handleContributorNameChange}
						comment={comment}
						handleCommentChange={handleCommentChange}
						death={death}
						handleDeathChange={handleDeathChange}
						injury={injury}
						handleInjuryChange={handleInjuryChange}
						propertyValue={propertyValue}
						handlePropertyValueChange={handlePropertyValueChange}
						noOfHouses={noOfHouses}
						handleNoOfHousesChange={handleNoOfHousesChange}
						propertyVandalized={propertyVandalized}
						handlePropertyVandalizedChange={handlePropertyVandalizedChange}
						handleReviewStatusChange={handleReviewStatusChange}
						selectedReviewStatusOption={selectedReviewStatusOption}
						optionsReviewStatus={optionsReviewStatus}
						listSelectedTags={listSelectedTags}
						listSetSelectedTags={listSetSelectedTags}
						handleSubmit={handleSubmit}
						options={options}
						listSelectedEvents={listSelectedEvents}
						listSetSelectedEvents={listSetSelectedEvents}
					/>

					{/* Modal for editing history document */}
					<EditHistoryModal
						isEditHistoryOpen={isEditHistoryOpen}
						onEditHistoryClose={onEditHistoryClose}
						editHistory={documentEditHistory}
					/>

					{/* Modal for showing file document */}
					<FilePreviewModal
						isOpen={isFileViewOpen}
						onClose={onFileViewClose}
						curFilename={curFilename}
						curFileType={curFileType}
						previewFile={previewFile}
						from={'library'}
						handleResyncSubmit={handleResyncSubmit}
					/>

					{/*  pagination  */}
					<Flex justify="center" mt={8}>
						<HStack spacing={2}>
							<IconButton
								aria-label="Previous Page"
								icon={<ChevronLeftIcon />}
								variant="outline"
								onClick={() => handlePageChange(currentPage - 1)}
								isDisabled={currentPage === 1}
							/>
							{
								currentPage > 1 &&
								<Button
									variant="outline"
									onClick={() => handlePageChange(currentPage - 1)}
								>{currentPage - 1}</Button>
							}

							<Button
								variant="outline"
								bg={'gray.200'}
							>
								{currentPage}
							</Button>

							{
								!(filteredData?.length < itemsPerPage) &&
								<Button
									variant="outline"
									onClick={() => handlePageChange(currentPage + 1)}
								>{currentPage + 1}</Button>
							}
							<IconButton
								aria-label="Next Page"
								icon={<ChevronRightIcon />}
								variant="outline"
								onClick={() => handlePageChange(currentPage + 1)}
								isDisabled={filteredData?.length < itemsPerPage}
							/>
						</HStack>
					</Flex>

					{/* About Our Library Section */}
					<About />
				</Box>
			</Box>
		</Layout>
	)
}
