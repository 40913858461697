import React, { useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ViewIcon, EditIcon, DeleteIcon, DownloadIcon } from '@chakra-ui/icons';
import { MdPreview } from "react-icons/md";
import ShowModal from '../ShowModal';


export default function LibraryResources({
  filteredData,
  handleDelete,
  handleEditOpen,
  handlePreview,
  handleDownload,
}) {
  // console.log(filteredData);
  // files.0.id/mime_type  "6791f95ced589939b2188603"
  // const filteredData = [
  //   {
  //     "id": "676ee82b6c4fb4a860cfb262",
  //     "data_type": 'blue',
  //     "created_at": "2024-12-27 17:47:23.752 +0000 UTC",
  //     "updated_at": "2024-12-27 17:47:23.752 +0000 UTC",
  //     "complex_category": {
  //       "tag": "মানবাধিকার",
  //       "broad_category": "সহিংসতা",
  //       "specific_category": [
  //         "হত্যা",
  //         "ধর্ষণ",
  //         "অন্যান্য-sdfjhds"
  //       ],
  //       "context": {
  //         "সংখ্যালঘু": {
  //           "ধর্ম ভিত্তিক": "মুসলিম",
  //           "লিঙ্গভিত্তিক": "নারী",
  //           "অন্যান্য": "অন্যান্য-sdfjhds"
  //         },
  //         "১৯৭১/মুক্তিযুদ্ধ": ["মুক্তিযোদ্ধা এবং তাঁদের পরিবার", "বীরাঙ্গনা এবং তাঁদের পরিবার", "অন্যান্য-sdfjhds"],
  //         "রাজনৈতিক": ["আওয়ামিলীগ ও অঙ্গসংগঠন"],
  //         "গণমাধ্যম": ["স্থাপনা"]
  //       }
  //     },
  //     "date_of_incident": "2024-12-25 00:00:00 +0000 UTC",
  //     "place_of_occurrence": {
  //       "district": "Dhaka",
  //       "upazila": "Dhanmondi",
  //       "village": "N/A",
  //       "phone": "+8801712345678"
  //     },
  //     "victim_information": [
  //       {
  //         "name": "Alice Johnson",
  //         "age": "35",
  //         "gender": "পুরুষ",
  //         "address": {
  //           "district": "Dhaka",
  //           "upazila": "Dhanmondi",
  //           "village": "N/A",
  //           "phone": "+8801712345679"
  //         },
  //         "occupation": "Teacher",
  //         "relation_with_victim": {
  //           "relation": "আত্মীয়",
  //           "extra": "N/A"
  //         }
  //       },
  //       {
  //         "name": "Alice Johnson",
  //         "age": "35",
  //         "gender": "পুরুষ",
  //         "address": {
  //           "district": "Dhaka",
  //           "upazila": "Dhanmondi",
  //           "village": "N/A",
  //           "phone": "+8801712345679"
  //         },
  //         "occupation": "Teacher",
  //         "relation_with_victim": {
  //           "relation": "আত্মীয়",
  //           "extra": "N/A"
  //         }
  //       }
  //     ],
  //     "those_accused": [
  //       {
  //         "unknown": 'false',
  //         "name": "Mark Smith",
  //         "age": "১৮ বছরের কম",
  //         "gender": "পুরুষ",
  //         "address": {
  //           "district": "Dhaka",
  //           "upazila": "Gulshan",
  //           "village": "N/A",
  //           "phone": "+8801712345680"
  //         },
  //         "occupation": "Unemployed",
  //         "organization": "পুলিশ"
  //       },
  //       {
  //         "unknown": 'false',
  //         "name": "Mark Smith",
  //         "age": "৩১ - ৪৫ বছর",
  //         "gender": "অন্যান্য",
  //         "address": {
  //           "district": "Dhaka",
  //           "upazila": "Gulshan",
  //           "village": "N/A",
  //           "phone": "+8801712345680"
  //         },
  //         "occupation": "employed",
  //         "organization": "ছাত্রলীগ/আওয়ামীলীগ"
  //       }
  //     ],
  //     "in_media": {
  //       "choice": "yes",
  //       // "extra": "Covered by local news outlet"
  //       "extra": "where:Covered, when:2021-04-05"
  //     },
  //     "authorities_informed": {
  //       // "choice": "yes",
  //       "choice": "no",
  //       // "extra": "Police informed immediately"
  //       // "extra": "YesDetails:Covered, NoWhy:"
  //       "extra": "YesDetails:, NoWhy:by local news outlet"
  //     },
  //     "step_by_government": {
  //       "choice": "yes",
  //       "extra": "Investigation ongoing"
  //     },
  //     "incident_description": "On December 25th, 2024, Alice Johnson was robbed at gunpoint in Dhanmondi while returning home from work.",
  //     "files": [
  //       {
  //         "id": "file12345",
  //         "mime_type": "application/pdf"
  //       }
  //     ],
  //     "provider_info": "Jane Doe, Lawyer"
  //   },

  // ];
  // const filteredData = [
  //   {
  //     "id": "676ee82b6c4fb4a860cfb262",
  //     "data_type": 'green',
  //     "created_at": "2024-12-27 17:47:23.752 +0000 UTC",
  //     "updated_at": "2024-12-27 17:47:23.752 +0000 UTC",
  //     "complex_category": {
  //       "tag": "মানবাধিকার",
  //       "broad_category": "সহিংসতা",
  //       "specific_category": [
  //         "হত্যা",
  //         "ধর্ষণ"
  //       ],
  //       "context": {
  //         "সংখ্যালঘু": {
  //           "ধর্ম ভিত্তিক": "মুসলিম",
  //           "লিঙ্গভিত্তিক": "নারী"
  //         },
  //         "১৯৭১/মুক্তিযুদ্ধ": ["মুক্তিযোদ্ধা এবং তাঁদের পরিবার", "বীরাঙ্গনা এবং তাঁদের পরিবার"],
  //         "রাজনৈতিক": ["আওয়ামিলীগ ও অঙ্গসংগঠন"],
  //         "গণমাধ্যম": ["স্থাপনা"]
  //       }
  //     },
  //     "source_details": "adfas ds fgds g sdfg fsg ",
  //     "publish_date": "2024-12-25 00:00:00 +0000 UTC",
  //     "incident_date": "2024-11-25 00:00:00 +0000 UTC",
  //     "place_of_occurrence": "sdfsd sgf ds dg",
  //     // "place_of_occurrence": {
  //     //   "district": "Dhaka",
  //     //   "upazila": "Dhanmondi",
  //     //   "village": "N/A",
  //     //   "phone": "+8801712345678"
  //     // },
  //     "files": [
  //       {
  //         "id": "file12345",
  //         "mime_type": "application/pdf"
  //       }
  //     ],
  //     "reporter": {
  //       "name": "yes",
  //       "phone": "56454484686"
  //     },
  //   },
  // ];


  function formatContext(context) {
    if (!context) return "N/A"; // Handle cases where context is undefined

    return Object.entries(context).map(([key, value]) => {
      if (Array.isArray(value)) {
        // Render arrays
        return (
          <span key={key} style={{ display: "block" }}>
            {key} <b>:</b> {value.join(", ")}
          </span>
        );
      } else if (typeof value === "object") {
        // Render objects
        return (
          <span key={key} style={{ display: "block" }}>
            {key} <b>:</b>
            <span style={{ paddingLeft: "1rem", display: "block" }}>
              {Object.entries(value).map(([subKey, subValue]) => (
                <span key={subKey} style={{ display: "block" }}>
                  {subKey} <b>:</b> {subValue}
                </span>
              ))}
            </span>
          </span>
        );
      } else {
        // Render other types
        return (
          <span key={key} style={{ display: "block" }}>
            {key} <b>:</b> {value}
          </span>
        );
      }
    });
  }


  // show
  const [isShowOpen, setIsShowOpen] = useState(false);
  const [incidentData, setIncidentData] = useState({});
  const handleShowOpen = (td) => { setIsShowOpen(true); setIncidentData(td); };
  const handleShowClose = () => { setIsShowOpen(false); setIncidentData({}); };


  return (
    <Box width="100%">
      {/* <Table variant="simple" > */}
      <Table variant="simple" display={{ base: "none", md: "block" }} >
        <Thead >
          <Tr >
            {/* {selectFlag && <Th><Checkbox /></Th>} */}
            <Th>ডাটা ধরন</Th>
            <Th>ট্যাগ</Th>
            <Th>অপরাধের বিস্তৃত ধরন</Th>
            <Th>অপরাধের নির্দিষ্ট ধরন</Th>
            <Th>বিস্তৃত এবং নির্দিষ্ট প্রেক্ষাপট</Th>
            {/* <Th>Specific Context</Th> */}
            <Th>ঘটনার তারিখ</Th>
            <Th textAlign="right"></Th>
          </Tr>
        </Thead>

        <Tbody width="90vw">
          {
            filteredData?.length > 0 ?
              filteredData?.map((td, i) =>
                <Tr key={td.id}>
                  {/* {
                    selectFlag &&
                    < Td >
                      <Checkbox
                        //bgColor={'gray'}
                        colorScheme={"gray"}
                        mb={[3, 3, 6]}
                        isChecked={selectedFile.includes(td.filename)}
                        onChange={() =>
                          setSelectFile((prev) =>
                            prev.includes(td.filename)
                              ? prev.filter((item) => item !== td.filename)
                              : [...prev, td.filename]
                          )
                        }
                      />
                    </Td>
                  } */}

                  <Td w="10vw">{td?.data_type || "N/A"}</Td>
                  <Td w="10vw">{td?.complex_category?.tag || "N/A"}</Td>

                  <Td w="10vw">{td?.complex_category?.broad_category || "N/A"}</Td>

                  <Td w="20vw">{Array.isArray(td?.complex_category?.specific_category) && td?.complex_category?.specific_category?.join(', ')}</Td>

                  <Td w="40vw">{formatContext(td?.complex_category?.context)}</Td>

                  <Td w="10vw">{td?.date_of_incident?.split(' ')[0] || td?.incident_date?.split(' ')[0] || "N/A"}</Td>

                  <Td w="10vw">
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-around" alignItems="center">

                      <Tooltip hasArrow label='Data Preview' bg='gray.300' color='black' placement='top'>
                        <IconButton
                          isDisabled={"waiting" === td.status ? true : false}
                          onClick={() => handleShowOpen(td)}
                          icon={<ViewIcon />}
                          aria-label="View"
                          size="sm"
                          ml={2}
                        />
                      </Tooltip>

                      <Menu>
                        <Tooltip hasArrow label="File Preview" bg="gray.300" color="black" placement="top">
                          <MenuButton
                            as={IconButton}
                            isDisabled={!(td?.files?.length > 0 || td?.green_fifth?.files?.length > 0)}
                            icon={<MdPreview size={20} />}
                            aria-label="View"
                            size="sm"
                            ml={2}
                          />
                        </Tooltip>
                        <MenuList>
                          {(td?.files || td?.green_fifth?.files)?.map((file, i) => (
                            <MenuItem
                              key={i}
                              onClick={() => handlePreview(file, td)}
                            >
                              File No. {i + 1}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>

                      <Tooltip hasArrow label='Files Download' bg='gray.300' color='black' placement='top'>
                        <IconButton
                          isDisabled={!(td?.files?.length > 0 || td?.green_fifth?.files?.length > 0)}
                          onClick={() => handleDownload(td?.files || td?.green_fifth?.files)}
                          icon={<DownloadIcon />}
                          aria-label="Download"
                          size="sm"
                          ml={2}
                        />
                      </Tooltip>

                      <Tooltip hasArrow label='Edit' bg='gray.300' color='black' placement='top'>
                        <IconButton
                          onClick={() => handleEditOpen(td)}
                          icon={<EditIcon />}
                          aria-label="Edit"
                          size="sm"
                          ml={2}
                        />
                      </Tooltip>

                      <Tooltip hasArrow label='Delete' bg='gray.300' color='black' placement='top'>
                        <IconButton
                          // isDisabled={"waiting" === td.status ? true : false}
                          onClick={() => handleDelete(td?.id)}
                          icon={<DeleteIcon />}
                          aria-label="Download"
                          size="sm"
                        />
                      </Tooltip>

                      {/* <Tooltip hasArrow label='Edit History' bg='gray.300' color='black' placement='top'>
                        <IconButton
                          // onClick={() => handleEditHistory(td.id)}
                          icon={<RepeatIcon />}
                          aria-label="Repeat"
                          size="sm"
                          ml={2}
                        />
                      </Tooltip> */}
                    </Box>
                  </Td>
                </Tr>
              )
              :
              <Tr>
                <Td>
                  <Text textAlign="center" my={4}>
                    তথ্য পাওয়া যায়নি ।
                  </Text>
                </Td>
              </Tr>
          }
        </Tbody>
      </Table>

      {/* Card layout for smaller screens */}
      <Box display={{ base: "block", md: "none" }}>
        {
          filteredData?.length > 0 ?
            filteredData?.map((td, i) =>
              <Box
                key={td.id}
                bg="white"
                shadow="md"
                rounded="lg"
                p={4}
                mb={4}
              >
                {/* {
                    selectFlag &&
                    <Checkbox
                      mr={2}
                      // mb={[3, 3, 6]}
                      isChecked={selectedFile.includes(td.filename)}
                      onChange={() =>
                        setSelectFile((prev) =>
                          prev.includes(td.filename)
                            ? prev.filter((item) => item !== td.filename)
                            : [...prev, td.filename]
                        )
                      }
                    />
                  } */}

                {/* {
                  td?.complex_category?.tag &&
                  <Text fontSize="sm" color="gray.500">
                    <Text as={'span'} fontSize="md" color="black"><strong>Title: </strong></Text>
                    {td?.complex_category?.tag}
                  </Text>
                } */}

                <Text><strong>ডাটা টাইপ :</strong> {td?.data_type || "N/A"}</Text>

                <Text><strong>ট্যাগ :</strong> {td?.complex_category?.tag || "N/A"}</Text>

                <Text><strong>অপরাধের বিস্তৃত ধরন :</strong> {td?.complex_category?.broad_category || "N/A"}</Text>

                <Text><strong>অপরাধের নির্দিষ্ট ধরন :</strong> {Array.isArray(td?.complex_category?.specific_category) ? td?.complex_category?.specific_category?.join(', ') : "N/A"}</Text>

                <Text><strong>বিস্তৃত এবং নির্দিষ্ট প্রেক্ষাপট :</strong> {formatContext(td?.complex_category?.context)}</Text>

                <Text><strong>ঘটনার তারিখ :</strong> {td?.date_of_incident?.split(' ')[0] || td?.incident_date?.split(' ')[0] || "N/A"}</Text>

                <Flex justify="center" gap={2}>
                  {/* <IconButton
                    isDisabled={"waiting" === td.status ? true : false}
                    // onClick={() => handleDownload(td?.filename)}
                    icon={<DownloadIcon />}
                    aria-label="Download"
                    size="sm"
                  /> */}

                  <IconButton
                    // isDisabled={"waiting" === td.status ? true : false}
                    onClick={() => handleShowOpen(td)}
                    icon={<ViewIcon />}
                    aria-label="View"
                    size="sm"
                  />

                  <Menu>
                    <MenuButton
                      as={IconButton}
                      isDisabled={!(td?.files?.length > 0 || td?.green_fifth?.files?.length > 0)}
                      icon={<MdPreview size={20} />}
                      aria-label="View"
                      size="sm"
                      ml={2}
                    />
                    <MenuList>
                      {(td?.files || td?.green_fifth?.files)?.map((file, i) => (
                        <MenuItem
                          key={i}
                          onClick={() => handlePreview(file, td)}
                        >
                          File No. {i + 1}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>

                  <IconButton
                    isDisabled={!(td?.files?.length > 0 || td?.green_fifth?.files?.length > 0)}
                    onClick={() => handleDownload(td?.files || td?.green_fifth?.files)}
                    icon={<DownloadIcon />}
                    aria-label="Download"
                    size="sm"
                    ml={2}
                  />

                  <IconButton
                    onClick={() => handleEditOpen(td)}
                    icon={<EditIcon />}
                    aria-label="Edit"
                    size="sm"
                  />

                  <IconButton
                    // isDisabled={"waiting" === td.status ? true : false}
                    onClick={() => handleDelete(td?.id)}
                    icon={<DeleteIcon />}
                    aria-label="Download"
                    size="sm"
                  />

                  {/* <IconButton
                    // onClick={() => handleEditHistory(td.id)}
                    icon={<RepeatIcon />}
                    aria-label="Repeat"
                    size="sm"
                  /> */}
                </Flex>
              </Box>
            )
            :
            <Text textAlign="center" my={4}>
              তথ্য পাওয়া যায়নি ।
            </Text>
        }
      </Box >

      {/* // show modal  */}
      <ShowModal
        isShowOpen={isShowOpen}
        handleShowClose={handleShowClose}
        incidentData={incidentData}
        formatContext={formatContext}
      />
    </Box >
  )
};

