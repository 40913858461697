import React, { } from 'react';
import { Box, Heading, Input, Button, Flex, Stack, FormControl, FormLabel, Menu, MenuButton, MenuList, MenuItem, Checkbox, Text, Select, CheckboxGroup, } from '@chakra-ui/react';

export default function SearchOptions({
  selectedTagOption,
  optionsTag,
  handleTagChange,
  selectedBoardCategoryOption,
  optionsBoardCategory,
  handleBoardCategoryChange,
  selectedSpecificCategoryOptions,
  optionsSpecificCategory,
  handleCheckboxSpecificCategoryChange,
  data2,
  selectedBroad,
  handleBroadSelect,
  selectedSpecific,
  handleSpecificSelect,
  // filteredData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleSearch,
}) {
  const { innerWidth } = window;

  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const [isDownloading, setIsDownloading] = useState(false);
  // const handleDownload = () => {
  //   setIsDownloading(true);

  //   setTimeout(() => {
  //     setIsDownloading(false);
  //     onClose(); // Close modal after download
  //   }, 1500);

  //   if (!selectFlag) handleAllDownload();
  //   else handleSelectFileDownload();
  // };


  return (
    <>
      {/* <Flex direction={{ base: "column", md: "row" }} gap={4} mb={6}>
        <Input placeholder="Search on Source Type Description" />

        <Select placeholder="Select Category">
          <option>Research Papers</option>
          <option>News Articles</option>
          <option>Reports</option>
          <option>Other</option>
        </Select>

        <Button colorScheme="red" variant="solid">Search</Button>
      </Flex> */}

      <Box
        // bg="white"
        // shadow="md"
        rounded="lg"
      // p={6}
      // mb={8}
      >
        <Heading
          as="h2"
          fontSize="2xl"
          fontWeight="semibold"
          color="gray.800"
          mb={4}
          fontFamily="Montserrat, sans-serif"
          textAlign={'center'}
        >
          রিসোর্স খুজুন
        </Heading>

        <form>
          <Flex mb={4} gap={4} direction={{ base: 'column', md: 'row' }} w={"100%"}>
            <FormControl w={["100%", "100%", "25%",]}>
              <FormLabel>ট্যাগ</FormLabel>
              <Select
                placeholder={"ট্যাগ নির্বাচন করুন"}
                size={["md", "md", "lg"]}
                variant="filled"
                focusBorderColor="blue.500"
                borderRadius="md"
                boxShadow="sm"
                onChange={handleTagChange}
                value={selectedTagOption}
              >
                {optionsTag.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl w={["100%", "100%", "25%",]}>
              <FormLabel>অপরাধের ধরন</FormLabel>
              <Select
                placeholder={"নির্বাচন করুন"}
                size={["md", "md", "lg"]}
                variant="filled"
                focusBorderColor="blue.500"
                borderRadius="md"
                boxShadow="sm"
                onChange={handleBoardCategoryChange}
                value={selectedBoardCategoryOption}
              >
                {optionsBoardCategory.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl w={["100%", "100%", "50%"]}>
              <FormLabel >অপরাধের নির্দিষ্ট ধরন</FormLabel>

              <Box w={['100%',]}>
                {/* Trigger button displaying selected options */}
                <Menu closeOnSelect={false}>
                  <MenuButton as={Button} w="100%" boxShadow="sm">
                    {selectedSpecificCategoryOptions.length > 0
                      ? `নির্বাচিত: ${selectedSpecificCategoryOptions.length} টি`
                      : "নির্বাচিত ধরন"}
                  </MenuButton>

                  {/* Dropdown list with checkboxes */}
                  <MenuList
                    // w={["90%", "100%", "50%"]}
                    w={[(innerWidth * 0.8), (innerWidth * 0.9), (innerWidth * 0.4)]}
                  // maxW="300px"
                  >
                    {optionsSpecificCategory.map((option) => (
                      <MenuItem key={option.value} closeOnSelect={false}>
                        <Checkbox
                          isChecked={selectedSpecificCategoryOptions.includes(option.value)}
                          onChange={() => handleCheckboxSpecificCategoryChange(option.value)}
                        >
                          {option.label}
                        </Checkbox>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>

                {/* Display the selected items below the dropdown, if any */}
                <Box mt={2}>
                  {selectedSpecificCategoryOptions.length > 0 && (
                    <Text fontSize="sm" color="gray.600">
                      নির্বাচিত: {selectedSpecificCategoryOptions.map((item) => optionsSpecificCategory.find(opt => opt.value === item).label).join(', ')}
                    </Text>
                  )}
                </Box>
              </Box>
            </FormControl>
          </Flex>

          {/*  search system  */}
          <Stack direction={["column", "column", "column", 'row']} width={['100%']} mb={4}>
            <Stack direction={["column", "column", 'row']} width={['100%', '100%', '100%', '50%', '60%']}>
              {/* <FormControl w={["100%", "100%", "50%"]}>
                <FormLabel >Broad Context(incident/victim)</FormLabel>

                <CheckboxGroup value={selectedBroad} onChange={handleBroadSelect}>
                  <Stack spacing={2}>
                    {data2["broad_context(incident/victim)"].map((item) => (
                      <Checkbox key={item} value={item}>
                        {item}
                      </Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              </FormControl> */}

              <FormControl w={["100%", "100%", "50%"]}>
                <FormLabel>অপরাধের প্রেক্ষাপট</FormLabel>

                <Box w={['100%']}>
                  {/* Trigger button displaying selected options */}
                  <Menu closeOnSelect={false}>
                    <MenuButton as={Button} w="100%" boxShadow="sm">
                      {selectedBroad.length > 0
                        ? `নির্বাচিত: ${selectedBroad.length} টি`
                        : "নির্বাচিত প্রেক্ষাপট"}
                    </MenuButton>

                    {/* Dropdown list with checkboxes */}
                    <MenuList w={[(innerWidth * 0.8), (innerWidth * 0.9), (innerWidth * 0.4)]}>
                      {data2["broad_context(incident/victim)"].map((item) => (
                        <MenuItem key={item} closeOnSelect={false}>
                          <Checkbox
                            isChecked={selectedBroad.includes(item)}
                            onChange={() => handleBroadSelect(item)}
                          >
                            {item}
                          </Checkbox>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>

                  {/* Display the selected items below the dropdown, if any */}
                  <Box mt={2}>
                    {selectedBroad.length > 0 && (
                      <Text fontSize="sm" color="gray.600">
                        নির্বাচিত: {selectedBroad.join(', ')}
                      </Text>
                    )}
                  </Box>
                </Box>
              </FormControl>

              {/* Render specific contexts for all selected broad contexts */}
              {selectedBroad.map((broadContext) => {
                const specificContexts = data2["specific_context(incident/victim)"][broadContext];
                if (!specificContexts) return null; // Skip if no specific context is available

                return (
                  <Box key={broadContext} mt={5} p={4} borderWidth={1} borderRadius="md">
                    <Heading size="sm" mb={3}>
                      {/* Specific Context / নির্দিষ্ট প্রেক্ষাপট -- {broadContext} */}
                      {broadContext}
                    </Heading>
                    {Array.isArray(specificContexts) ? (
                      // For flat contexts (e.g., "রাজনৈতিক")
                      <CheckboxGroup
                        value={selectedSpecific[broadContext] || []}
                        onChange={(values) => handleSpecificSelect(broadContext, null, values)}
                      >
                        <Stack spacing={2}>
                          {specificContexts.map((option) => (
                            <Checkbox key={option} value={option}>
                              {option}
                            </Checkbox>
                          ))}
                        </Stack>
                      </CheckboxGroup>
                    ) : (
                      // For nested contexts (e.g., "সংখ্যালঘু")
                      Object.entries(specificContexts).map(([subCategory, options]) => (
                        <Box key={subCategory} mb={3}>
                          <Text fontWeight="bold" mb={2}>
                            {subCategory}
                          </Text>
                          <CheckboxGroup
                            value={(selectedSpecific[broadContext]?.[subCategory] || [])}
                            onChange={(values) => handleSpecificSelect(broadContext, subCategory, values)}
                          >
                            <Stack spacing={2}>
                              {options.map((option) => (
                                <Checkbox key={option} value={option}>
                                  {option}
                                </Checkbox>
                              ))}
                            </Stack>
                          </CheckboxGroup>
                        </Box>
                      ))
                    )}
                  </Box>
                );
              })}
            </Stack>

            <Stack direction={["column", "row"]} width={['100%', '100%', '100%', '50%', '40%']}>
              <Stack direction={["row",]} width={['100%', '80%', '80%', '80%']}>
                <FormControl width={'50%'}>
                  <FormLabel>শুরুর তারিখ</FormLabel>
                  <Input
                    type='date'
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </FormControl>

                <FormControl width={'50%'}>
                  <FormLabel>শেষ তারিখ</FormLabel>
                  <Input
                    type='date'
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </FormControl>
              </Stack>

              <Button
                width={['100%', '20%']}
                bg="red.600"
                _hover={{ bg: 'red.700' }}
                color="white"
                mt={[0, 8]}
                // type="submit"
                onClick={handleSearch}
              >
                খুজুন
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </>
  );
};