import {
    REQUEST_SUBMIT_FILE_ARCHIVE_DATA,
    SUCCESS_SUBMIT_FILE_ARCHIVE_DATA,
    FAILURE_SUBMIT_FILE_ARCHIVE_DATA,
    REQUEST_SUBMIT_ARCHIVE_DATA_BLUE,
    SUCCESS_SUBMIT_ARCHIVE_DATA_BLUE,
    FAILURE_SUBMIT_ARCHIVE_DATA_BLUE,
    REQUEST_SUBMIT_ARCHIVE_DATA,
    SUCCESS_SUBMIT_ARCHIVE_DATA,
    FAILURE_SUBMIT_ARCHIVE_DATA,
    REQUEST_SUBMIT_ARCHIVE_HISTORY,
    SUCCESS_SUBMIT_ARCHIVE_HISTORY,
    FAILURE_SUBMIT_ARCHIVE_HISTORY,
    REQUEST_GET_LIST_ARCHIVE,
    SUCCESS_GET_LIST_ARCHIVE,
    FAILURE_GET_LIST_ARCHIVE,
    REQUEST_UPDATE_ARCHIVE,
    SUCCESS_UPDATE_ARCHIVE,
    FAILURE_UPDATE_ARCHIVE,
    REQUEST_UPDATE_EDIT_HISTORY_ARCHIVE,
    SUCCESS_UPDATE_EDIT_HISTORY_ARCHIVE,
    FAILURE_UPDATE_EDIT_HISTORY_ARCHIVE,
    REQUEST_FILE_DOWNLOAD_ARCHIVE,
    SUCCESS_FILE_DOWNLOAD_ARCHIVE,
    FAILURE_FILE_DOWNLOAD_ARCHIVE,
    REQUEST_GET_ARCHIVE_EDIT_HISTORY,
    SUCCESS_GET_ARCHIVE_EDIT_HISTORY,
    FAILURE_GET_ARCHIVE_EDIT_HISTORY,
    REQUEST_GET_ARCHIVE_SEARCH_RESULT,
    SUCCESS_GET_ARCHIVE_SEARCH_RESULT,
    FAILURE_GET_ARCHIVE_SEARCH_RESULT,

    // UPDATE_SESSION_EXPIRY,
} from "../Constant/archive";


export const requestSubmitFileArchiveData = () => ({
    type: REQUEST_SUBMIT_FILE_ARCHIVE_DATA,
})
export const submitFileArchiveDataSuccess = (data) => ({
    type: SUCCESS_SUBMIT_FILE_ARCHIVE_DATA,
    payload: data,
})
export const submitFileArchiveDataFailure = (error) => ({
    type: FAILURE_SUBMIT_FILE_ARCHIVE_DATA,
    payload: error,
})

export const requestSubmitArchiveDataBlue = () => ({
    type: REQUEST_SUBMIT_ARCHIVE_DATA_BLUE,
})
export const submitArchiveDataBlueSuccess = (data) => ({
    type: SUCCESS_SUBMIT_ARCHIVE_DATA_BLUE,
    payload: data,
})
export const submitArchiveDataBlueFailure = (error) => ({
    type: FAILURE_SUBMIT_ARCHIVE_DATA_BLUE,
    payload: error,
})

export const requestSubmitArchiveData = () => ({
    type: REQUEST_SUBMIT_ARCHIVE_DATA,
})
export const submitArchiveDataSuccess = (data) => ({
    type: SUCCESS_SUBMIT_ARCHIVE_DATA,
    payload: data,
})
export const submitArchiveDataFailure = (error) => ({
    type: FAILURE_SUBMIT_ARCHIVE_DATA,
    payload: error,
})

export const requestSubmitArchiveHistory = () => ({
    type: REQUEST_SUBMIT_ARCHIVE_HISTORY,
})
export const submitArchiveHistorySuccess = (data) => ({
    type: SUCCESS_SUBMIT_ARCHIVE_HISTORY,
    payload: data,
})
export const submitArchiveHistoryFailure = (error) => ({
    type: FAILURE_SUBMIT_ARCHIVE_HISTORY,
    payload: error,
})

export const requestGetListArchive = () => ({
    type: REQUEST_GET_LIST_ARCHIVE,
})
export const getListArchiveSuccess = (data) => ({
    type: SUCCESS_GET_LIST_ARCHIVE,
    payload: data,
})
export const getListArchiveFailure = (error) => ({
    type: FAILURE_GET_LIST_ARCHIVE,
    payload: error,
})

export const requestUpdateArchive = () => ({
    type: REQUEST_UPDATE_ARCHIVE,
})
export const updateArchiveSuccess = (data) => ({
    type: SUCCESS_UPDATE_ARCHIVE,
    payload: data,
})
export const updateArchiveFailure = (error) => ({
    type: FAILURE_UPDATE_ARCHIVE,
    payload: error,
})

export const requestUpdateEditHistoryArchive = () => ({
    type: REQUEST_UPDATE_EDIT_HISTORY_ARCHIVE,
})
export const updateEditHistoryArchiveSuccess = (data) => ({
    type: SUCCESS_UPDATE_EDIT_HISTORY_ARCHIVE,
    payload: data,
})
export const updateEditHistoryArchiveFailure = (error) => ({
    type: FAILURE_UPDATE_EDIT_HISTORY_ARCHIVE,
    payload: error,
})

export const requestFileDownloadArchive = () => ({
    type: REQUEST_FILE_DOWNLOAD_ARCHIVE,
})
export const fileDownloadArchiveSuccess = (data) => ({
    type: SUCCESS_FILE_DOWNLOAD_ARCHIVE,
    payload: data,
})
export const fileDownloadArchiveFailure = (error) => ({
    type: FAILURE_FILE_DOWNLOAD_ARCHIVE,
    payload: error,
})

export const requestGetArchiveEditHistory = () => ({
    type: REQUEST_GET_ARCHIVE_EDIT_HISTORY,
})
export const getArchiveEditHistorySuccess = (data) => ({
    type: SUCCESS_GET_ARCHIVE_EDIT_HISTORY,
    payload: data,
})
export const getArchiveEditHistoryFailure = (error) => ({
    type: FAILURE_GET_ARCHIVE_EDIT_HISTORY,
    payload: error,
})

export const requestGetArchiveSearchResult = () => ({
    type: REQUEST_GET_ARCHIVE_SEARCH_RESULT,
})
export const getArchiveSearchResultSuccess = (data) => ({
    type: SUCCESS_GET_ARCHIVE_SEARCH_RESULT,
    payload: data,
})
export const getArchiveSearchResultFailure = (error) => ({
    type: FAILURE_GET_ARCHIVE_SEARCH_RESULT,
    payload: error,
})



// export const updateSessionExpiry = (nextDay) => ({
//     type: UPDATE_SESSION_EXPIRY,
//     payload: nextDay,
// })
