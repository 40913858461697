import React, { useEffect, useState } from 'react';
import { Heading, Container, VStack, Text, Flex, Box, HStack, IconButton, Button, } from '@chakra-ui/react';
// import { Form, } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import { useDispatch, useSelector } from 'react-redux';
import { selectID, selectProfile, selectToken, selectUserHistory } from '../../Redux/Reducer';
import { getUserHistoryData } from '../../Redux/Thunk/Login';

import Table from './Table';
import Layout from '../../Layout/Layout';
import { selectUserName } from '../../Redux/Reducer/index';


const UserProfile = () => {
    const dispatch = useDispatch();
    const { innerHeight } = window;

    const token = useSelector(state => selectToken(state));
//    const profile = useSelector(state => selectProfile(state));
    const user_details = useSelector(state=>selectUserName(state));

    const userID = useSelector(state => selectID(state));
    // console.log(userID);
    // const userID = '66b819414d894e054f978e69';
    const data = useSelector(state => selectUserHistory(state));
    // console.log(data);


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);  // Default to 20 items per page

    // initial data load and after recall
    useEffect(() => {
        dispatch(getUserHistoryData((currentPage - 1) * itemsPerPage, itemsPerPage, token, userID));
    }, [dispatch, token, currentPage, itemsPerPage, userID]);

    const [tableData, setTableData] = useState([]);

    // data set 
    useEffect(() => {
        // if (data && data.userHistory) {
        if (data) {
            setTableData(data);
        }
    }, [data]);

    const handlePageChange = (page) => {
        if (page >= 1) {
            setCurrentPage(page);
        }
    };


    return (
        <Layout>
            <Container
                pb={{ base: '2', md: '4' }}
                px={{ base: '3', md: '8' }}
                maxWidth="1400px"
                mx="auto"
                minH={innerHeight * 0.747}
            >
                <Box
                    maxWidth={"1200px"}
                    mx={'auto'}
                    // border='4px'
                    // borderColor='green'
                    // borderRadius={15} 
                    my={3}
                    p={[2, 6]}
                    boxShadow="dark-lg"
                    rounded="md"
                    w="100%"
                >
                    <Flex
                        mx={'auto'}
                        my={3}
                        align={"center"}
                        justify={"space-evenly"}
                        direction={['column-reverse', 'column-reverse', 'row',]}
                    >
                        <VStack my={[1, 1, 8]}>
                            <Heading fontWeight={'bold'} as='h2' fontSize={['xl', '2xl']}>
                                User Profile Details
                            </Heading>

                            <Box>
                                <Text fontWeight={'bold'} fontSize={['sm', 'md']} >First Name : {user_details?.first_name}</Text>
                                <Text fontWeight={'bold'} fontSize={['sm', 'md']} >Last Name : {user_details?.last_name}</Text>
                                <Text fontWeight={'bold'} fontSize={['sm', 'md']} >Email : {user_details?.email}</Text>
                            </Box>
                        </VStack>
                    </Flex>
                </Box>

                <Box
                    maxWidth={"1200px"}
                    mx={'auto'}
                    // border='4px'
                    // borderColor='green'
                    // borderRadius={15} 
                    my={3}
                    p={[2, 6]}
                    boxShadow="dark-lg"
                    rounded="md"
                    w="100%"
                >
                    <Table
                        currentPage={currentPage}
                        // setCurrentPage={setCurrentPage}
                        itemsPerPage={itemsPerPage}
                        // setItemsPerPage={setItemsPerPage}
                        tableData={tableData}
                    // handlePageChange={handlePageChange}
                    />

                    {/*  pagination  */}
                    <Flex justify="center" mt={8}>
                        <HStack spacing={2}>
                            <IconButton
                                aria-label="Previous Page"
                                icon={<ChevronLeftIcon />}
                                variant="outline"
                                onClick={() => handlePageChange(currentPage - 1)}
                                isDisabled={currentPage === 1}
                            />
                            {
                                currentPage > 1 &&
                                <Button
                                    variant="outline"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                >{currentPage - 1}</Button>
                            }

                            <Button
                                variant="outline"
                                bg={'gray.200'}
                            // isDisabled={currentPage === 1}
                            >
                                {currentPage}
                            </Button>

                            {
                                !(tableData?.length < itemsPerPage) &&
                                <Button
                                    variant="outline"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                >{currentPage + 1}</Button>
                            }
                            <IconButton
                                aria-label="Next Page"
                                icon={<ChevronRightIcon />}
                                variant="outline"
                                onClick={() => handlePageChange(currentPage + 1)}
                                isDisabled={tableData?.length < itemsPerPage}
                            />
                        </HStack>
                    </Flex>
                </Box>
            </Container >
        </Layout>
    );
};

export default UserProfile;