export const welcomeText = "সহিংসতা, ধ্বংসযজ্ঞ, নৈরাজ্য ও হুমকির তথ্য-সংগ্রহ ফর্ম";
export const instructionText = "(ন্যায়বিচার ও ক্ষতিপূরণ প্রক্রিয়ার সহায়তার্থে ১ জুলাই ২০২৪ থেকে এখন পর্যন্ত সংঘটিত সকল সহিংস ঘটনার তথ্য দিন)";

export const blue_form_instructionText = "নির্দেশনাঃ কেবলমাত্র আপনার কিংবা আপনার পরিচিত কারও সাথে ঘটা/ঘটতে দেখা অপরাধের তথ্য প্রদান করুন। আপনার দেওয়া প্রতিটি তথ্যই অত্যন্ত গুরুত্ব সহকারে বিবেচনা করা হবে, এবং সর্বোচ্চ গোপনীয়তা রক্ষা করা হবে। আপনার কিংবা নির্যাতিত ব্যাক্তির পরিচয় দেওয়া বাধ্যতামূলক নয়। তবে এই তথ্যগুলো না দিলে আমাদের পক্ষে যাচাই করা সম্ভব হবে না। সেক্ষেত্রে আপনার রিপোর্টটি অকার্যকর তথ্য হিসেবে বিবেচিত হবে। আপনি যদি চান যে, আপনার তথ্যটি ব্যবহৃত হোক, তাহলে আপনার বা যার সম্পর্কে তথ্য দিচ্ছেন তার পরিচয় ও যোগাযোগের তথ্য দেওয়াটা অত্যাবশ্যকীয়। যথাযথ গোপনীয়তা এবং নিরাপত্তা রক্ষা করে গবেষণার স্বার্থে ঘটনার সত্যতা যাচাইয়ের জন্য আমরা আপনার সাথে যোগাযোগ করতে পারি ।";

export const green_form_instructionText = "নির্দেশনাঃ এই ফরমটি কেবলমাত্র পত্রিকা কিংবা যেকোনো মিডিয়ায় প্রকাশিত খবর রিপোর্টিং-এর জন্য । পত্রিকার নাম, প্রকাশের তারিখ অবশ্যই পূরণ করতে হবে। আপনার কাছে যদি কোন ভিডিও কিংবা ছবি/চিত্র বা কোনো দলিল থাকে, সেগুলোর স্থান, সময় ইত্যাদি অবশ্যই দিতে হবে জেনো সেগুলোর সত্যতা যাচাই করা যায়। এসকল ক্ষেত্রে এই ফর্মে আপনার পরিচয় প্রকাশ করাটা অত্যন্ত জরুরী যেন এ ধরনের তথ্যের সত্যতা যাচাইয়ের জন্য আপনার সাথে আমরা আবার যোগাযোগ করতে পারি। আমরা অত্যন্ত বিনয়ের সাথে জানাচ্ছি যে এই পদক্ষেপগুলো না নিলে আমরা আমাদের গবেষণায় আপনার দেওয়া তথ্যটি ব্যাবহার করতে পারবো না । আমরা এটাও নিশ্চিত করতে চাই যে নিরপাত্তার খাতিরে আপনার পরিচয় সবসময়ই গোপন রাখা হবে ।";

export const acknowledgementText = "It will be acknowledgement type text here, later update.";

export const submitButtonText = "Submit";
export const submitWaitingText = "Please wait for response";


export const dateInputHeader = "ঘটনার সময় (যদি জানা থাকে)";
export const dateInputId = "date";
export const dateInputPlaceholder = "Select Date and Time";

export const attackPlaceCategoriesInputHeader = "আক্রমণের স্থান/ক্ষেত্র (যদি প্রযোজ্য হয়)";

export const categoriesInputHeader = "আক্রমণের ধরণ (যদি প্রযোজ্য হয়)";
export const categoriesInputPlaceholder = "Click to Select Categories";

export const sourceTypeInputHeader = "Source Type";
export const sourceTypeInputPlaceholder = "Select Source Type Option";

export const urlInputHeader = "URL";
export const urlInputId = "url";
export const urlInputPlaceholder = "Type your url here..";

export const shortDescriptionInputHeader = "সংক্ষিপ্ত বিবরণী (প্রয়োজনে এটাচমেন্টে ওয়ার্ড/পিডিএফ ফাইল সংযুক্ত করে আরো বিস্তারিত জানাতে পারেন। যত বিশদ জানাবেন, আমাদের জন্য ততো সহজ হবে আপনার দেয়া তথ্য যাচাই করা) ";
export const shortDescriptionInputId = "shortDescription";
export const shortDescriptionInputPlaceholder = "Your answer";

export const descriptionInputHeader = "উপরে উল্লেখ্য তথ্যগুলোর বাইরেও আপনার বিবেচনায় যে কোনো বিশ্লেষণ, প্রতিবেদন, মতামত, বিবৃতি, ঘোষণা, স্ট্যাটাস, টুইট,  বিবরণী, দলিল - যা আপনার বিবেচনায় সার্বিক পরিস্থিতির আলোকে প্রাসঙ্গিক (প্রত্যক্ষ বা পরোক্ষভাবে) কিংবা বুঝতে সহায়ক - তা সংক্ষেপে বর্ণনা করুন";
export const descriptionInputId = "description";
export const descriptionInputPlaceholder = "Your answer";

export const fileInputHeader = "এটাচমেন্ট ও প্রমাণাদি (যেমন: ছবি, ভিডিও, স্ক্রিনশট, ওয়ার্ড/পিডিএফ ফাইল ইত্যাদি)।";

export const nameInputHeader = "তথ্যদাতার নাম (ঐচ্ছিক; তবে যদি আপনার সাথে আমাদের দিক থেকে সরাসরি যোগাযোগে সহায়তা করতে চান, তাহলে উল্লেখ করুন) ";
export const nameInputId = "name";
export const nameInputPlaceholder = "Your answer";

export const victimInputHeader = "আক্রান্ত ব্যক্তির/পরিবারের নাম (জানা থাকলে / যদি প্রযোজ্য হয়)";
export const victimInputId = "victim";
export const victimInputPlaceholder = "Your answer";

export const addressInputHeader = "আক্রান্ত ব্যক্তির/পরিবারের ঠিকানা (গ্রাম, পোস্ট অফিস, উপজেলা, জেলা - যদি প্রযোজ্য হয়)";
export const addressInputId = "address";
export const addressInputPlaceholder = "Your answer";

export const occupationInputHeader = "আক্রান্ত ব্যক্তির পেশা ও কর্মস্থল (যদি প্রযোজ্য হয়)";
export const occupationInputId = "occupation";
export const occupationInputPlaceholder = "Your answer";

export const emailInputHeader = "তথ্যদাতার ফোন নাম্বার ও ইমেইল (ঐচ্ছিক; তবে যদি আপনার সাথে আমাদের দিক থেকে সরাসরি যোগাযোগে সহায়তা করতে চান, তাহলে উল্লেখ করুন)";
export const emailInputId = "email";
export const emailInputPlaceholder = "Your answer";

export const victimConditionInputHeader = "আক্রান্ত ব্যক্তির/পরিবারের বর্তমান অবস্থা (যদি প্রযোজ্য হয়)";

export const radioButtonInputHeader2 = "Header Name 2(Optional)"; // need to change the name


export const welcomeTextLinkToPdfPage = "";
export const instructionTextLinkToPdfPage = "Submission Form";

export const submitButtonTextLinkToPdfPage = "Submit";

export const dateInputHeaderLinkToPdfPage = "Date";
export const dateInputIdLinkToPdfPage = "date";
export const dateInputPlaceholderLinkToPdfPage = "Select Date and Time";

export const categoriesInputHeaderLinkToPdfPage = "Catagories";
export const categoriesInputPlaceholderLinkToPdfPage = "Click to Select Categories";

export const sourceTypeInputHeaderLinkToPdfPage = "Source";
export const sourceTypeInputPlaceholderLinkToPdfPage = "Select Source Type";

export const urlInputHeaderLinkToPdfPage = "URL";
export const urlInputIdLinkToPdfPage = "url";
export const urlInputPlaceholderLinkToPdfPage = "Type your url here..";

export const commentInputHeaderLinkToPdfPage = "Add Comment";
export const commentInputIdLinkToPdfPage = "comment";
export const commentInputPlaceholderLinkToPdfPage = "Type your comment here..";


export const searchOptionInputHeader = "Search by Option";
export const searchInputPlaceholder = "Search By Input";
export const searchOptionInputPlaceholder = "Select Search Option By Category";


export const homeText1 = "Welcome! Thank you for deciding to take part in this initiative";
export const homeText1b = "স্বাগতম! এই উদ্যোগে অংশগ্রহণ করার জন্য আপনাকে ধন্যবাদ।";

export const homeText2 = "The purpose of this platform is to facilitate documentation of conflict violence and incitement from all sides between 1 July 2024 and now.";
export const homeText2b = "এই প্ল্যাটফর্মটি গত ১ জুলাই ২০২৪ থেকে আজ অবধি বাংলাদেশে সংঘটিত সকল প্রকার সংঘাত, প্রাণহানী, ও উস্কানিমূলক কর্মকান্ড নথিবদ্ধ করার উদ্দ্যেশ্যে তৈরি করা হয়েছে।";

export const homeText3 = "This initiative aims to support analysis, research, accountability, justice and healing process";
export const homeText3b = "এই উদ্যোগের উদ্দেশ্য সংগঠিত সংঘাতের বিশ্লেষণ, গবেষণা, দায় নিরূপণ, প্রতিকার ও বিচার প্রক্রিয়াকে সহায়তা করা।";

export const homeText4 = "Please submit your evidence.";
export const homeText4b = "অনুগ্রহ করে আপনার সংগ্রহে থাকা তথ্য/সাক্ষ্য/প্রমাণ জমা দিন।";
