import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectExpiryTime, selectIsLoggedIn } from '../Redux/Reducer';
import { logout } from '../Redux/Thunk/Login';

const UserRoute = ({ children }) => {
    const dispatch = useDispatch();

    // const isLoggedIn = true;
    const isLoggedIn = useSelector(state => selectIsLoggedIn(state));
    const expiry_time = useSelector(state => selectExpiryTime(state));
    // const expiry_time = Date.now() + 10 * 60 * 60 * 1000; // 10 hours from now

    // useEffect(() => {
    //     const timeOutID = setTimeout(() => {
    //         const curTime = new Date().getTime();
    //         console.log(curTime);
    //         console.log(expiry_time);
    //         // if (expiry_time == null || curTime > expiry_time) {
    //         //     dispatch(logout());
    //         // }

    //     }, 2000);

    //     return () => { clearTimeout(timeOutID) };
    // });
    // // }, [expiry_time, dispatch]);
    useEffect(() => {
        const intervalID = setInterval(() => {
            const curTime = new Date().getTime();
            // console.log(curTime);
            // console.log(expiry_time);
            if (!expiry_time || curTime > expiry_time) {
                dispatch(logout());
                // console.log('dfg');
                clearInterval(intervalID); // Stop the interval if logout is triggered
            }
        }, 2000);

        return () => clearInterval(intervalID); // Cleanup interval on unmount
    }, [expiry_time, dispatch]); // Re-run effect only if `expiry_time` changes

    if (isLoggedIn) {
        return children;
    }
    return <Navigate to='/login'></Navigate>;
};

export default UserRoute;