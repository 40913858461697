import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button, Stack, Text, ModalHeader, ModalCloseButton, VStack, } from "@chakra-ui/react";

import FilePreview from "../Components/FilePreview";

const FilePreviewModal = ({ isOpen, onClose, curFilename, curFileType, previewFile, from, handleResyncSubmit }) => {
    // console.log(previewFile);

    return (
        <Modal
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            p={0}
            isOpen={isOpen}
            onClose={onClose}
            size="full"
        >
            <ModalOverlay />

            <ModalContent
                style={
                    {
                        // backgroundColor: 'transparent',
                        backgroundColor: 'gray',
                        display: 'flex',
                        flexDirection: 'column',
                        columnGap: 0,
                        gap: 0
                    }
                }
                w={{ base: '100vw', md: '80vw', lg: '70vw' }} // Force full width on mobile
                maxW="none" // Ensure no max-width limit on mobile
                borderRadius="0" // Remove border-radius on mobile to fit full screen
                p={0}
            >
                <ModalHeader color={"white"} textAlign={'center'}>File Preview</ModalHeader>

                <ModalCloseButton color={"white"} fontSize={'20'} fontWeight={'900'} />

                <ModalBody
                    style={
                        {
                            flex: 3,
                            width: "100%",
                            overflow: 'hidden',
                            backgroundColor: 'gray',
                            marginBottom: 0,
                            paddingBottom: 0
                        }
                    }
                    p={0}
                    m={0}
                >
                    {
                        (from !== "fromList") &&
                        <Stack p={5} flexDirection={["column", "column", "row"]} >
                            <VStack w={['100%', '100%', "30%"]} align={['center', 'center', 'flex-start']}>
                                <Text color={"white"} >Date : {previewFile?.date}</Text>

                                <Text color={"white"} >Location : {previewFile?.location ?? previewFile?.place}</Text>
                            </VStack>

                            <VStack w={['100%', '100%', "70%"]} align={['center', 'center', 'flex-start']}>
                                <Text color={"white"} >Categories : {Array.isArray(previewFile?.categories) && previewFile?.categories?.join(', ')}</Text>

                                <Text color={"white"} >Tags : {Array.isArray(previewFile?.tags) && previewFile?.tags?.join(', ')}</Text>

                                <Text color={"white"} >Events : {Array.isArray(previewFile?.events) && previewFile?.events?.join(', ')}</Text>
                            </VStack>
                        </Stack>
                    }

                    <FilePreview
                        fileUrl={(from !== "fromList") ? `https://bdconflict.watch/api/v1/makepdf/downloaddirect/${curFilename}` : `https://bdconflict.watch/api/v1/ivpfile/read/${curFilename}`}
                        fileType={curFileType}
                    />
                </ModalBody>

                <ModalFooter
                    style={
                        {
                            backgroundColor: 'transparent',
                            flex: 2,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }
                    }
                    p={2}
                    m={2}
                >
                    {
                        (previewFile?.description === "webpage") && (from === 'library') &&
                        previewFile?.download_id && previewFile?.source_url && (previewFile?.source_type || previewFile?.description) &&
                        < Button
                            mr={40}
                            bgColor="gray.900"
                            color="white"
                            _hover={{ bg: "red.900", color: "white" }}
                            style={{ marginTop: 0, paddingTop: 0 }}
                            onClick={handleResyncSubmit}
                        >
                            Resync
                        </Button>
                    }

                    <Button
                        bgColor="red.900"
                        color="white"
                        _hover={{ bg: "gray.900", color: "white" }}
                        style={{ marginTop: 0, paddingTop: 0 }}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default FilePreviewModal;