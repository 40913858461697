import React from 'react';
import { Box, Text, Grid, Flex, Icon, useBreakpointValue, Stack, HStack, VStack, Heading } from '@chakra-ui/react';
import { FaSkull, FaUserInjured, FaHome, FaHospital, FaAmbulance, FaStar } from 'react-icons/fa';
import Footer from '../Layout/Footer';


const AboutUs = () => {
	// const columns = useBreakpointValue({ base: 1, md: 2, lg: 3 });


	return (
		<>
			<Box
				px={{ base: '2', md: '4', }}
				py={{ base: '4', md: '8' }}
				maxW={{ base: "98vw", md: "80vw" }}
				mx="auto"
				minH="57.1vh"
			>
				{/* <Box
						bg="rgba(0, 0, 0, 0.7)"
						p={{ base: 6, md: 10 }}
						borderRadius="md"
						justifyItems={'center'}
						minH="100vh"
					> */}


				{/* <Text fontSize={{ base: 'xs', md: 'sm' }} color="black" fontWeight="700" > */}
				<Text fontSize={['xl']} color="black" fontWeight="900" my={10} textAlign={'center'}>
					About Us
				</Text>


				<Text fontSize={{ base: 'lg', md: 'lg' }} color="black" fontWeight="500" textAlign={'center'} >
					A documentation initiative of International Crimes Strategy Forum (ICSF) to facilitate analysis, research, justice, accountability, and reparation. The initiative is supported by other secular and human rights groups.
				</Text>

				{/* <Box align="left"> */}
				<Box align="center">
					{/* <Heading
						as="h2"
						size="lg"
						mb="6"
						color="gray.800"
						fontFamily="Montserrat, sans-serif"
					>
						About Bangladesh Conflict Watch
					</Heading> */}

					<Box
						bg="white"
						// boxShadow="md"
						p="6"
						rounded="lg"
					>
						{/* <Text color="gray.600" mb="4"> */}
						<Text mb="4">
							Bangladesh Conflict Watch is dedicated to monitoring and reporting conflicts across Bangladesh. Our mission is to promote peace, understanding, and conflict resolution through accurate and timely information.
						</Text>

						{/* <Text color="gray.600"> */}
						<Text >
							We rely on reports from citizens, journalists, and organizations to build a comprehensive database of incidents. This information is crucial for researchers, policymakers, and peace activists working towards a more stable and harmonious Bangladesh.
						</Text>

						{/* <Button variant="outline" colorScheme="gray">
							Learn More
						</Button> */}
					</Box>
				</Box>


			</Box >

			<Footer />
		</>
	);
};

export default AboutUs;
