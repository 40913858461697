import { Button, Box, Heading, Text, Grid, VStack } from '@chakra-ui/react';
// import { useState } from 'react';
import Layout from '../Layout/Layout';
import DescriptionCards from '../Components/DescriptionCards';

export default function Home() {
	//   const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<Layout>
			<Box
				px={{ base: '2', md: '4', }}
				py={{ base: '4', md: '8' }}
				maxW={{ base: "98vw", md: "80vw" }} mx="auto"
			>

				<Box mb="12">
					{/* <Heading
						textAlign="left"
						as="h1"
						size="xl"
						mb="4"
						color="gray.800"
						fontFamily="Montserrat, sans-serif"
					>
						Bangladesh Conflict Watch
					</Heading> */}

					{/* <Text
						textAlign="left"
						fontSize="xl"
						mb="8"
						color="gray.600"
					>
						Monitoring and reporting conflicts to promote peace and understanding.
					</Text> */}

					{/* <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap="8"> */}
					<Grid templateColumns={{ base: '1fr', md: '1fr' }} gap="8">
						<Box align="left">
							<Heading
								as="h2"
								size="lg"
								mb="4"
								color="gray.800"
								fontFamily="Montserrat, sans-serif"
							>
								{/* Report an Incident */}
								ঘটনা রিপোর্ট করুন
							</Heading>
							<Text color="gray.600" mb="4">
								{/* Help us track conflicts by reporting incidents you've witnessed or heard about. */}
								আপনার দেখা বা শোনা ঘটনাগুলি রিপোর্ট করে ট্র্যাক করতে আমাদের সাহায্য করুন।
							</Text>
							<Button colorScheme="red" variant="solid" as="a" href="blue_form">
								{/* Report Now */}
								এখনই রিপোর্ট করুন
							</Button>
						</Box>
						{/*
			<Box bg="white" boxShadow="md" p="6" rounded="lg">
				<Heading textAlign="left" as="h2" size="lg" mb="4" color="gray.800" fontFamily="Montserrat, sans-serif">
				Quick Search
				</Heading>
				<form>
				<VStack spacing="4">
					<Box align ="left" w="100%">
					<Text fontSize="sm" fontWeight="medium" color="gray.700" mb="1">
						Location
					</Text>
					<Input placeholder="Enter a location" />
					</Box>
					<Box align="left" w="100%">
					<Text fontSize="sm" fontWeight="medium" color="gray.700" mb="1">
						Incident Type
					</Text>
					<Select>
						<option>All Types</option>
						<option>Violence</option>
						<option>Protest</option>
						<option>Political Unrest</option>
						<option>Other</option>
					</Select>
					</Box>
					<Button type="submit" colorScheme="gray" w="100%">
					Search
					</Button>
				</VStack>
				</form>
			</Box>
			*/}
					</Grid>
				</Box>


				{/*
		<Box mb="12">
			<Heading as="h2" size="lg" mb="6" color="gray.800" fontFamily="Montserrat, sans-serif">
			Recent Incidents
			</Heading>
			<Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap="6">
			{[1, 2, 3].map((i) => (
				<Box key={i} bg="white" boxShadow="md" rounded="lg" overflow="hidden">
				<Image src={`/placeholder.svg?height=200&width=400&text=Incident+${i}`} alt={`Incident ${i}`} w="100%" h="200px" objectFit="cover" />
				<Box align="left" p="4">
					<Heading as="h3" size="md" mb="2" fontFamily="Montserrat, sans-serif">
					Incident Title {i}
					</Heading>
					<Text color="gray.600" mb="4">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
					</Text>
					<Flex justify="space-between" color="gray.500" fontSize="sm">
					<Text>Location {i}</Text>
					<Text>Date: 2023-05-{10 + i}</Text>
					</Flex>
				</Box>
				</Box>
			))}
			</Grid>
			<Box textAlign="center" mt="8">
			<Button variant="outline" colorScheme="gray">
				View All Incidents
			</Button>
			</Box>
		</Box>
		*/}

				<Box
					// rowSpan={3}
					// colSpan={[5, 5, 3, 3, 3]}
					// boxShadow='dark-lg'
					// p={[3, 6]}
					rounded='md'
					w={'100%'}
					mb={8}
				>
					<VStack spacing={[5, 5, 5, 6]} align={'left'}>
						{/* Description Cards */}
						<DescriptionCards />
					</VStack>
				</Box>
			</Box>
		</Layout>
	);
}
