import { combineReducers } from "redux";
import data, * as fromData from "./data";
import user, * as fromUser from "./login";
import blueFormListDocuments, * as fromBlueFormListDocuments from "./blueFormList";
import blueDataUpdate, * as fromUpdateBlueData from "./blueFormList";
import blueDatasAction, * as fromActionBlueDatas from "./blueFormList";
import fileInfo, * as fromInfoFile from "./blueFormList";
import fileDownloadForm, * as fromDownloadFormFile from "./blueFormList";
import listDocuments, * as fromListDocuments from "./listDocuments";
import dataUpdate, * as fromUpdateData from "./listDocuments";
import dataUpdateEditHistory, * as fromUpdateEditHistoryData from "./listDocuments";
import fileDownload, * as fromDownloadFile from "./listDocuments";
import documentEditHistory, * as fromDocumentEditHistory from "./listDocuments";
import screen, * as fromScreen from "./screen";
import fileArchiveData, * as fromDataFileArchive from "./archive";
import archiveDataBlue, * as fromDataBlueArchive from "./archive";
import archiveData, * as fromDataArchive from "./archive";
import archiveHistory, * as fromHistoryArchive from "./archive";
import listArchive, * as fromListArchive from "./archive";
import archiveUpdate, * as fromUpdateArchive from "./archive";
import archiveUpdateEditHistory, * as fromUpdateEditHistoryArchive from "./archive";
import fileDownloadArchive, * as fromDownloadArchiveFile from "./archive";
import archiveEditHistory, * as fromArchiveEditHistory from "./archive";
import listUser, * as fromListUser from "./user_list";
import listTags, * as fromListTags from "./tags";
import tagsAction, * as fromActionTags from "./tags";
import listEvents, * as fromListEvents from "./events";
import eventsAction, * as fromActionEvents from "./events";


export const rootReducer = combineReducers({
    "data": data,
    "user": user,
    "user_list": listUser,
    // "userHistory": userHistory,
    "blueFormListDocuments": blueFormListDocuments,
    "blueDataUpdate": blueDataUpdate,
    "blueDatasAction": blueDatasAction,
    "fileInfo": fileInfo,
    "fileDownloadForm": fileDownloadForm,
    "listDocuments": listDocuments,
    "dataUpdate": dataUpdate,
    "dataUpdateEditHistory": dataUpdateEditHistory,
    "fileDownload": fileDownload,
    "documentEditHistory": documentEditHistory,
    "fileArchiveData": fileArchiveData,
    "archiveDataBlue": archiveDataBlue,
    "archiveData": archiveData,
    "archiveHistory": archiveHistory,
    "listArchive": listArchive,
    "archiveUpdate": archiveUpdate,
    "archiveUpdateEditHistory": archiveUpdateEditHistory,
    "fileDownloadArchive": fileDownloadArchive,
    "archiveEditHistory": archiveEditHistory,
    "listTags": listTags,
    "tagsAction": tagsAction,
    "listEvents": listEvents,
    "eventsAction": eventsAction,
    "screen": screen,
});

const reducerFunction = (state, action) => rootReducer(state, action);
export default reducerFunction;


export const selectID = state => fromUser.selectUserID(state.user);
export const selectToken = state => fromUser.selectToken(state.user);
export const selectIsLoggedIn = state => fromUser.selectIsLoggedIn(state.user);
export const selectIsAdmin = state => fromUser.selectIsAdmin(state.user);
export const selectIsSuperAdmin = state => fromUser.selectIsSuperAdmin(state.user);
export const selectProfile = state => fromUser.selectProfile(state.user);
export const selectUserLoginErrorMessage = state => fromUser.selectUserLoginErrorMessage(state.user);
export const selectUpdateMessage = state => fromUser.selectUpdateMessage(state.user);
export const selectUserName = state => fromUser.selectUserName(state.user);

export const selectUserHistory = state => fromUser.selectUserHistory(state.user);
export const selectUpdatePassword = state => fromUser.selectUpdatePassword(state.user);

export const selectExpiryTime = state => fromUser.selectExpiryTime(state.user);
// console.log(selectExpiryTime);


export const selectUserList = state => fromListUser.selectUserList(state.user_list);



export const selectData = state => fromData.selectData(state.data);


export const selectListTags = state => fromListTags.selectListTags(state.listTags);
export const selectActionTags = state => fromActionTags.selectActionTags(state.tagsAction);

export const selectListEvents = state => fromListEvents.selectListEvents(state.listEvents);
export const selectActionEvents = state => fromActionEvents.selectActionEvents(state.eventsAction);


export const selectBlueFormListDocuments = state => fromBlueFormListDocuments.selectBlueFormListDocuments(state.blueFormListDocuments.blueFormListDocumentsData);
export const selectUpdateBlueData = state => fromUpdateBlueData.selectUpdateBlueData(state.blueDataUpdate);
export const selectActionBlueDatas = state => fromActionBlueDatas.selectActionBlueDatas(state.blueDatasAction);
export const selectDownloadFormFile = state => fromDownloadFormFile.selectDownloadFormFile(state.fileDownloadForm);
export const selectInfoFile = state => fromInfoFile.selectInfoFile(state.fileInfo);

export const selectListDocuments = state => fromListDocuments.selectListDocuments(state.listDocuments.listDocumentsData);
export const selectUpdateData = state => fromUpdateData.selectUpdateData(state.dataUpdate);
export const selectUpdateEditHistoryData = state => fromUpdateEditHistoryData.selectUpdateEditHistoryData(state.dataUpdateEditHistory);
export const selectDownloadFile = state => fromDownloadFile.selectDownloadFile(state.fileDownload);
export const selectDocumentEditHistory = state => fromDocumentEditHistory.selectDocumentEditHistory(state.documentEditHistory.documentEditHistoryData.documentEditHistory);


export const selectDataFileArchive = state => fromDataFileArchive.selectDataFileArchive(state.fileArchiveData);
export const selectDataBlueArchive = state => fromDataBlueArchive.selectDataBlueArchive(state.archiveDataBlue);
export const selectDataArchive = state => fromDataArchive.selectDataArchive(state.archiveData);
export const selectHistoryArchive = state => fromHistoryArchive.selectHistoryArchive(state.archiveHistory);

export const selectListArchive = state => fromListArchive.selectListArchive(state.listArchive.listArchiveData);
export const selectUpdateArchive = state => fromUpdateArchive.selectUpdateArchive(state.archiveUpdate);
export const selectUpdateEditHistoryArchive = state => fromUpdateEditHistoryArchive.selectUpdateEditHistoryArchive(state.archiveUpdateEditHistory);
export const selectDownloadArchiveFile = state => fromDownloadArchiveFile.selectDownloadArchiveFile(state.fileDownloadArchive);
export const selectArchiveEditHistory = state => fromArchiveEditHistory.selectArchiveEditHistory(state.archiveEditHistory.archiveEditHistoryData.archiveEditHistory);

export const selectCurrentScreen = state => fromScreen.selectCurrentScreen(state.screen);

// admin
// export const selectAllUserData = state => fromUserManagement.selectAllUserData(state.userManagement.usersData);
