import React, { useState } from 'react';
//import { Menu,MenuGroup,MenuItem, MenuButton,MenuList,Box, Text,Flex, Link, Image, IconButton, Button, Collapse, Stack, VStack } from '@chakra-ui/react';
import { Box, Flex, Link, Image, IconButton, Button, Collapse, VStack, Menu, MenuButton, MenuList, MenuGroup, MenuItem, Text } from '@chakra-ui/react';
import { SearchIcon, CloseIcon, HamburgerIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { logout, } from '../Redux/Thunk/Login';
import { selectIsAdmin, selectToken, selectID, selectIsSuperAdmin, selectIsLoggedIn } from '../Redux/Reducer';
import { useLocation, useNavigate } from 'react-router-dom';

const Header = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	let location = useLocation();

	// const isAdmin = true;
	const isAdmin = useSelector(state => selectIsAdmin(state));
	// const isSuperAdmin = true;
	const isSuperAdmin = useSelector(state => selectIsSuperAdmin(state));
	const token = useSelector(state => selectToken(state));
	const user_id = useSelector(state => selectID(state));
	// const loggedIn = true;
	const loggedIn = useSelector(state => selectIsLoggedIn(state));

	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	const handleLogOut = () => {
		dispatch(logout(user_id, token));
	};

	return (
		<Box bg="#B9B9AA" >
			<Box maxW="98vw" mx="auto" px={4} py={4}>
				<Flex justify="space-between" align="center">
					{/* Logo Section */}
					<Flex align="center" boxShadow='none'>
						<Button
							boxShadow='none'
							filter='none'
							variant='ghost'
							onClick={() => navigate("/")}
							bgColor={'transparent'}
							color='white'
							_hover={{ bg: "transparent", color: "white" }}
						>
							<Image
								src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Bangladesh%C2%A0Conflct%C2%A0Watch-5Zd8HndRbKDaTImTmW8mYi5x8nigYR.png"
								alt="Bangladesh Conflict Watch Logo"
								h={12}
								mr={4}
							/>
						</Button>
					</Flex>

					{/* Navigation Menu for larger screens */}
					{
						loggedIn
							?
							<Flex
								direction='row'
								gap={8}
								display={{ base: 'none', md: 'flex' }}
								align="center"
								spacing={8}
							>
								<Link href="/home" fontWeight={location.pathname === '/home' ? 'bold' : 'normal'} _hover={{ color: 'red.600' }}>
									Home
								</Link>

								{
									isSuperAdmin &&
									<Link href="/user_list" fontWeight={location.pathname === '/user_list' ? 'bold' : 'normal'} _hover={{ color: 'red.600' }}>
										Users
									</Link>
								}

								{
									isAdmin &&
									<Link href="/tag" fontWeight={location.pathname === '/tag' ? 'bold' : 'normal'} _hover={{ color: 'red.600' }}>
										Tags
									</Link>
								}

								{
									isAdmin &&
									<Link href="/event" fontWeight={location.pathname === '/event' ? 'bold' : 'normal'} _hover={{ color: 'red.600' }}>
										Events
									</Link>
								}

								<Menu>
									{/* <MenuButton as={Button} */}
									<MenuButton as={Link}
										// size="sm"
										// fontWeight='bold'
										// rounded="md"
										// color='white'
										// p='1'
										// bg="green.500"
										// _hover={{ bg: "green", color: "white" }}
										fontWeight={(location.pathname === '/blue_form') || (location.pathname === '/green_form') || (location.pathname === '/form_list') ? 'bold' : 'normal'}
										_hover={{ color: 'red.600' }}
									>
										Incident
										<ChevronDownIcon fontSize="2xl" />
									</MenuButton>

									<MenuList bg={'gray'}>
										{/* <MenuGroup title='Profile' color='white'> */}
										<MenuGroup>
											<MenuItem px={3} py={1} bg='gray'>
												<Link
													w={'100%'}
													href='/blue_form'
													fontWeight={(location.pathname === '/blue_form') ? 'bold' : 'normal'}
												>
													<Text display="block" color='white' _hover={{ color: 'red.100' }}>Incident-Blue Form</Text>
												</Link>
											</MenuItem>

											{/* <MenuDivider /> */}

											<MenuItem px={3} py={1} bg='gray'>
												<Link
													w={'100%'}
													href='/green_form'
													fontWeight={(location.pathname === '/green_form') ? 'bold' : 'normal'}
												>
													<Text display="block" color='white' _hover={{ color: 'red.100' }} >Incident-Green Form</Text>
												</Link>
											</MenuItem>

											{/* <MenuDivider /> */}

											<MenuItem px={3} py={1} bg='gray'>
												<Link
													w={'100%'}
													href='/form_list'
													fontWeight={(location.pathname === '/form_list') ? 'bold' : 'normal'}
												>
													<Text display="block" color='white' _hover={{ color: 'red.100' }} >Form Data List</Text>
												</Link>
											</MenuItem>
										</MenuGroup>
									</MenuList>
								</Menu>

								<Menu>
									{/* <MenuButton as={Button} */}
									<MenuButton as={Link}
										// size="sm"
										// fontWeight='bold'
										// rounded="md"
										// color='white'
										// p='1'
										// bg="green.500"
										// _hover={{ bg: "green", color: "white" }}
										fontWeight={(location.pathname === '/report') || (location.pathname === '/archive_upload') ? 'bold' : 'normal'}
										_hover={{ color: 'red.600' }}
									>
										Upload
										<ChevronDownIcon fontSize="2xl" />
									</MenuButton>

									<MenuList bg={'gray'}>
										{/* <MenuGroup title='Profile' color='white'> */}
										<MenuGroup>
											<MenuItem px={3} py={1} bg='gray'>
												<Link
													w={'100%'}
													href='/report'
													fontWeight={(location.pathname === '/report') ? 'bold' : 'normal'}
												>
													<Text display="block" color='white' _hover={{ color: 'red.100' }}>Link</Text>
												</Link>
											</MenuItem>

											{/* <MenuDivider /> */}

											<MenuItem px={3} py={1} bg='gray'>
												<Link
													w={'100%'}
													href='/archive_upload'
													fontWeight={(location.pathname === '/archive_upload') ? 'bold' : 'normal'}
												>
													<Text display="block" color='white' _hover={{ color: 'red.100' }} >File</Text>
												</Link>
											</MenuItem>
										</MenuGroup>

									</MenuList>
								</Menu>

								<Link
									href="/library"
									fontWeight={location.pathname === '/library' ? 'bold' : 'normal'}
									_hover={{ color: 'red.600' }}
								>
									Library
								</Link>

								<Link
									href="/archive_list"
									fontWeight={location.pathname === '/archive_list' ? 'bold' : 'normal'}
									_hover={{ color: 'red.600' }}
								>
									Archive List
								</Link>

								<Menu>
									<MenuButton as={Link}
										// size="sm"
										// fontWeight='bold'
										// rounded="md"
										// color='white'
										// p='1'
										// bg="green.500"
										// _hover={{ bg: "green", color: "white" }}
										fontWeight={(location.pathname === '/profile') || (location.pathname === '/update_password') ? 'bold' : 'normal'}
										_hover={{ color: 'red.600' }}
									>
										Profile
										<ChevronDownIcon fontSize="2xl" />
									</MenuButton>

									<MenuList bg={'gray'}>
										{/* <MenuGroup title='Profile' color='white'> */}
										<MenuGroup>
											<MenuItem px={3} py={1} bg='gray'>
												<Link href='/profile' w={'100%'}
													fontWeight={(location.pathname === '/profile') ? 'bold' : 'normal'}
												>
													<Text display="block" color='white' _hover={{ color: 'red.100' }}>User Profile</Text>
												</Link>
											</MenuItem>

											{/* <MenuDivider /> */}

											<MenuItem px={3} py={1} bg='gray'>
												<Link href='/update_password' w={'100%'}
													fontWeight={(location.pathname === '/update_password') ? 'bold' : 'normal'}
												>
													<Text display="block" color='white' _hover={{ color: 'red.100' }}>Update Password</Text>
												</Link>
											</MenuItem>
										</MenuGroup>
									</MenuList>
								</Menu>

								<Button
									size="sm"
									fontWeight='bold'
									rounded="md"
									color='red.600'
									p='1'
									bg="#B9B9AA"
									_hover={{ bg: "red.600", color: "white" }}
									onClick={handleLogOut}
								>
									Log Out
								</Button>
							</Flex>
							:
							<Flex
								direction='row'
								gap={8}
								display={{ base: 'none', md: 'flex' }}
								align="center"
								spacing={8}
							>
								{/* <Link href="/blue_form"
									fontWeight={location.pathname === '/blue_form' ? 'bold' : 'normal'}
									_hover={{ color: 'red.600' }}
								>
									Blue Form
								</Link> */}

								<Link href="/login"
									// fontWeight={location.pathname === '/home' ? 'bold' : 'normal'}
									_hover={{ color: 'red.600' }}
								>
									Login
								</Link>

								<Link
									href="/register"
									// fontWeight={location.pathname === 'blue_form' ? 'bold' : 'normal'}
									_hover={{ color: 'red.600' }}
								>
									Register
								</Link>
							</Flex>
					}

					{/* Search and Mobile Menu Button */}
					<Flex align="center" boxShadow='none'>
						<IconButton
							icon={<SearchIcon />}
							variant="ghost"
							filter='none'
							aria-label="Search"
							//color="gray.800"
							boxShadow='none'
							_hover={{ color: 'red.600' }}
							mr={2}
						/>
						<IconButton
							icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
							variant="ghost"
							aria-label="Toggle Menu"
							//color="gray.800"
							boxShadow='none'
							filter="none"
							_hover={{ color: 'red.600' }}
							display={{ base: 'flex', md: 'none' }}
							onClick={toggle}
						/>
					</Flex>
				</Flex>
			</Box>

			{/* Mobile Navigation Menu */}
			<Collapse in={isOpen} animateOpacity>
				<Box bg="white" display={{ md: 'none' }}>
					<Box maxW="1200px" mx="auto" px={4} py={4}>
						{
							loggedIn
								?
								<VStack spacing={2} align="start">
									<Link href="/home" color="gray.600" fontWeight={location.pathname === '/home' ? 'bold' : 'normal'} _hover={{ color: 'red.600' }}>
										Home
									</Link>

									{
										isSuperAdmin &&
										<Link href="/user_list" color="gray.600" fontWeight={location.pathname === '/user_list' ? 'bold' : 'normal'} _hover={{ color: 'red.600' }}>
											Users
										</Link>
									}

									{
										isAdmin &&
										<Link href="/tag" color="gray.600" fontWeight={location.pathname === '/tag' ? 'bold' : 'normal'} _hover={{ color: 'red.600' }}>
											Tags
										</Link>
									}

									{
										isAdmin &&
										<Link href="/event" color="gray.600" fontWeight={location.pathname === '/event' ? 'bold' : 'normal'} _hover={{ color: 'red.600' }}>
											Events
										</Link>
									}

									<Menu>
										{/* <MenuButton as={Button} */}
										<MenuButton as={Link}
											// size="sm"
											// fontWeight='bold'
											// rounded="md"
											// color='white'
											// p='1'
											// bg="green.500"
											// _hover={{ bg: "green", color: "white" }}
											fontWeight={(location.pathname === '/blue_form') || (location.pathname === '/green_form') || (location.pathname === '/form_list') ? 'bold' : 'normal'}
											_hover={{ color: 'red.600' }}
										>
											Incident
											<ChevronDownIcon fontSize="2xl" />
										</MenuButton>

										<MenuList bg={'gray'}>
											{/* <MenuGroup title='Profile' color='white'> */}
											<MenuGroup>
												<MenuItem px={3} py={1} bg='gray'>
													<Link
														href='/blue_form'
														w={'100%'}
														fontWeight={(location.pathname === '/blue_form') ? 'bold' : 'normal'}
													>
														<Text display="block" color='white' _hover={{ color: 'red.100' }}>Incident-Blue Form</Text>
													</Link>
												</MenuItem>

												{/* <MenuDivider /> */}

												<MenuItem px={3} py={1} bg='gray'>
													<Link
														href='/green_form'
														w={'100%'}
														fontWeight={(location.pathname === '/green_form') ? 'bold' : 'normal'}
													>
														<Text display="block" color='white' _hover={{ color: 'red.100' }} >Incident-Green Form</Text>
													</Link>
												</MenuItem>

												{/* <MenuDivider /> */}

												<MenuItem px={3} py={1} bg='gray'>
													<Link
														href='/form_list'
														w={'100%'}
														fontWeight={(location.pathname === '/form_list') ? 'bold' : 'normal'}
													>
														<Text display="block" color='white' _hover={{ color: 'red.100' }} >Form Data List</Text>
													</Link>
												</MenuItem>
											</MenuGroup>
										</MenuList>
									</Menu>

									<Menu>
										{/* <MenuButton as={Button} */}
										<MenuButton as={Link}
											// size="sm"
											// fontWeight='bold'
											// rounded="md"
											// color='white'
											// p='1'
											// bg="green.500"
											// _hover={{ bg: "green", color: "white" }}
											fontWeight={(location.pathname === '/report') || (location.pathname === '/archive_upload') ? 'bold' : 'normal'}
											_hover={{ color: 'red.600' }}
										>
											Upload
											<ChevronDownIcon fontSize="2xl" />
										</MenuButton>

										<MenuList bg={'gray'}>
											{/* <MenuGroup title='Profile' color='white'> */}
											<MenuGroup>
												<MenuItem px={3} py={1} bg='gray'>
													<Link
														href='/report'
														w={'100%'}
														fontWeight={(location.pathname === '/report') ? 'bold' : 'normal'}
													>
														<Text display="block" color='white' _hover={{ color: 'red.100' }}>Link</Text>
													</Link>
												</MenuItem>

												{/* <MenuDivider /> */}

												<MenuItem px={3} py={1} bg='gray'>
													<Link
														href='/archive_upload'
														w={'100%'}
														fontWeight={(location.pathname === '/archive_upload') ? 'bold' : 'normal'}
													>
														<Text display="block" color='white' _hover={{ color: 'red.100' }} >File</Text>
													</Link>
												</MenuItem>
											</MenuGroup>

										</MenuList>
									</Menu>

									<Link href="/library" color="gray.600" fontWeight={location.pathname === '/library' ? 'bold' : 'normal'} _hover={{ color: 'red.600' }}>
										Library
									</Link>

									<Link href="/archive_list" color="gray.600" fontWeight={location.pathname === '/archive_list' ? 'bold' : 'normal'} _hover={{ color: 'red.600' }}>
										Archive List
									</Link>

									<Menu>
										<MenuButton as={Link}
											// size="sm"
											// fontWeight='bold'
											// rounded="md"
											// color='white'
											// p='1'
											// bg="green"
											// _hover={{ bg: "green.400", color: "white" }}
											fontWeight={(location.pathname === '/profile') || (location.pathname === '/update_password') ? 'bold' : 'normal'}
											_hover={{ color: 'red.600' }}
										>
											Profile
											<ChevronDownIcon fontSize="2xl" />
										</MenuButton>

										<MenuList bg={'gray'}>
											{/* <MenuGroup title='Profile' color='white'> */}
											<MenuGroup>
												<MenuItem px={3} py={1} bg='gray'>
													<Link href='/profile' w={'100%'} fontWeight={(location.pathname === '/profile') ? 'bold' : 'normal'}>
														<Text display="block" color='white' _hover={{ color: 'red.100' }}>User Profile</Text>
													</Link>
												</MenuItem>

												{/* <MenuDivider /> */}

												<MenuItem px={3} py={1} bg='gray'>
													<Link href='/update_password' w={'100%'} fontWeight={(location.pathname === '/update_password') ? 'bold' : 'normal'}>
														<Text display="block" color='white' _hover={{ color: 'red.100' }}>Update Password</Text>
													</Link>
												</MenuItem>
											</MenuGroup>
										</MenuList>
									</Menu>

									<Button
										size="sm"
										fontWeight='bold'
										rounded="md"
										color='red.600'
										p='1'
										bg="white"
										_hover={{ bg: "red.600", color: "white" }}
										onClick={handleLogOut}
									>
										Log Out
									</Button>
								</VStack>
								:
								<VStack spacing={2} align="start">
									{/* <Link href="/blue_form" color="gray.600"
										fontWeight={location.pathname === '/blue_form' ? 'bold' : 'normal'}
										_hover={{ color: 'red.600' }}>
										Blue Form
									</Link> */}

									<Link href="login" color="gray.600"
										// fontWeight={location.pathname === 'blue_form' ? 'bold' : 'normal'} 
										_hover={{ color: 'red.600' }}>
										Login
									</Link>

									<Link href="/register" color="gray.600"
										// fontWeight={location.pathname === '/library' ? 'bold' : 'normal'} 
										_hover={{ color: 'red.600' }}>
										Register
									</Link>
								</VStack>
						}
					</Box>
				</Box>
			</Collapse>
		</Box>
	);
};

export default Header;
