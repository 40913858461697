
export const REQUEST_GET_BLUE_FORM_LIST_DOCUMENTS = "REQUEST_GET_BLUE_FORM_LIST_DOCUMENTS";
export const SUCCESS_GET_BLUE_FORM_LIST_DOCUMENTS = "SUCCESS_GET_BLUE_FORM_LIST_DOCUMENTS";
export const FAILURE_GET_BLUE_FORM_LIST_DOCUMENTS = "FAILURE_GET_BLUE_FORM_LIST_DOCUMENTS";

export const REQUEST_UPDATE_BLUE_DATA = "REQUEST_UPDATE_BLUE_DATA";
export const SUCCESS_UPDATE_BLUE_DATA = "SUCCESS_UPDATE_BLUE_DATA";
export const FAILURE_UPDATE_BLUE_DATA = "FAILURE_UPDATE_BLUE_DATA";

// export const REQUEST_UPDATE_EDIT_HISTORY_DOCUMENT = "REQUEST_UPDATE_EDIT_HISTORY_DOCUMENT";
// export const SUCCESS_UPDATE_EDIT_HISTORY_DOCUMENT = "SUCCESS_UPDATE_EDIT_HISTORY_DOCUMENT";
// export const FAILURE_UPDATE_EDIT_HISTORY_DOCUMENT = "FAILURE_UPDATE_EDIT_HISTORY_DOCUMENT";

export const REQUEST_FILE_INFO = "REQUEST_FILE_INFO";
export const SUCCESS_FILE_INFO = "SUCCESS_FILE_INFO";
export const FAILURE_FILE_INFO = "FAILURE_FILE_INFO";

export const REQUEST_FILE_DOWNLOAD_FORM = "REQUEST_FILE_DOWNLOAD_FORM";
export const SUCCESS_FILE_DOWNLOAD_FORM = "SUCCESS_FILE_DOWNLOAD_FORM";
export const FAILURE_FILE_DOWNLOAD_FORM = "FAILURE_FILE_DOWNLOAD_FORM";

// export const REQUEST_GET_DOCUMENT_EDIT_HISTORY = "REQUEST_GET_DOCUMENT_EDIT_HISTORY";
// export const SUCCESS_GET_DOCUMENT_EDIT_HISTORY = "SUCCESS_GET_DOCUMENT_EDIT_HISTORY";
// export const FAILURE_GET_DOCUMENT_EDIT_HISTORY = "FAILURE_GET_DOCUMENT_EDIT_HISTORY";

export const REQUEST_GET_BLUE_FORM_SEARCH_RESULT = "REQUEST_GET_BLUE_FORM_SEARCH_RESULT";
export const SUCCESS_GET_BLUE_FORM_SEARCH_RESULT = "SUCCESS_GET_BLUE_FORM_SEARCH_RESULT";
export const FAILURE_GET_BLUE_FORM_SEARCH_RESULT = "FAILURE_GET_BLUE_FORM_SEARCH_RESULT";

export const REQUEST_DELETE_BLUE_DATA = "REQUEST_DELETE_BLUE_DATA";
export const SUCCESS_DELETE_BLUE_DATA = "SUCCESS_DELETE_BLUE_DATA";
export const FAILURE_DELETE_BLUE_DATA = "FAILURE_DELETE_BLUE_DATA";

