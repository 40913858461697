//const BASE_URL = "http://localhost:9090";
const BASE_URL = "https://bdconflict.watch";


// login
export const signup_url = `${BASE_URL}/api/v1/users/registration`;                    // ok
export const login_url = `${BASE_URL}/api/v1/users/login`;                            // ok
export const logout_url = `${BASE_URL}/api/v1/users/logout`;                          // ok
export const profileUpdate_url = `${BASE_URL}/api/v1/profile_edit`;

export const password_update_url = `${BASE_URL}/api/v1/users/password/update`;
export const get_user_history_url = (skip, limit, userID) => `${BASE_URL}/api/v1/edithistory/personallist/${skip}/${limit}/${userID}`;

// home
export const data_url = `${BASE_URL}/api/v1/evidence/create`;                          // ok

// report
export const add_links_data_url = `${BASE_URL}/api/v1/makepdf/create`;                  // ok

// library
export const get_list_documents_url = (skip, limit, processed) => `${BASE_URL}/api/v1/makepdf/list/${skip}/${limit}/${processed}`;   // ok
export const file_download_url = `${BASE_URL}/api/v1/makepdf/download`;                 // ok
export const data_update_url = `${BASE_URL}/api/v1/makepdf/update`;                     // ok
export const data_update_edit_history_url = `${BASE_URL}/api/v1/edithistory/create`;    // ok
// library search
export const get_search_list_documents_url = `${BASE_URL}/api/v1/makepdf/search`;    // 
// library edit history
export const get_document_edit_history_url = (skip, limit, id) => `${BASE_URL}/api/v1/edithistory/list/${skip}/${limit}/${id}`;   // ok

// archive upload
export const archive_file_upload_url = `${BASE_URL}/api/v1/ivpfile/create`;            // ok
export const archive_blue_upload_url = `${BASE_URL}/api/v1/proof/create`;            // ok
export const archive_upload_url = `${BASE_URL}/api/v1/evidence/multicreate`;            // ok

// archive
export const get_list_archive_url = (skip, limit, processed) => `${BASE_URL}/api/v1/evidence/list/${skip}/${limit}/${processed}`;   // ok
export const file_download_archive_url = `${BASE_URL}/api/v1/makepdf/download`;         // ok
export const archive_update_url = `${BASE_URL}/api/v1/evidence/update`;                 // ok
export const archive_update_edit_history_url = `${BASE_URL}/api/v1/edithistory/create`;    // ok
// archive search
export const get_search_list_archive_url = `${BASE_URL}/api/v1/evidence/search`;    // 
// archive edit history
export const get_archive_edit_history_url = (skip, limit, id) => `${BASE_URL}/api/v1/edithistory/list/${skip}/${limit}/${id}`;   // ok

// user list
export const get_user_list_url = `${BASE_URL}/api/v1/users/list`;                       // ok
export const user_update_url = `${BASE_URL}/api/v1/users/update`;                       //ok

// tag
export const get_tag_list_url = (skip, limit) => `${BASE_URL}/api/v1/tag/list/${skip}/${limit}`;   // ok
export const tag_create_url = `${BASE_URL}/api/v1/tag/create`;                          // ok
export const tag_delete_url = `${BASE_URL}/api/v1/tag/delete`;                          // 

// event
export const get_event_list_url = (skip, limit) => `${BASE_URL}/api/v1/event/list/${skip}/${limit}`;   // 
export const event_create_url = `${BASE_URL}/api/v1/event/create`;                 // 
export const event_delete_url = `${BASE_URL}/api/v1/event/delete`;                 // 

// blue Form
export const get_blue_form_list_documents_url = (skip, limit) => `${BASE_URL}/api/v1/proof/list/${skip}/${limit}`;   // o
export const get_blue_form_search_list_documents_url = `${BASE_URL}/api/v1/proof/findct`;    // 
export const blue_data_update_url = `${BASE_URL}/api/v1/proof/update`;
export const blue_data_delete_url = `${BASE_URL}/api/v1/proof/delete`;                 // 
export const file_info_url = (id) => `${BASE_URL}/api/v1/ivpfile/get/${id}`;         // ok
export const file_download_form_url = (id) => `${BASE_URL}/api/v1/ivpfile/download/${id}`;         // ok

