import React, { } from 'react';
import {
    Box,
    Text,
    ModalFooter,
    Button,
    VStack,
    ModalBody,
    ModalCloseButton,
    ModalHeader,
    ModalContent,
    ModalOverlay,
    Modal,
    Divider
} from "@chakra-ui/react";


export default function ShowModal({
    isShowOpen,
    handleShowClose,
    incidentData,
    formatContext,
}) {

    // console.log(incidentData);

    return (
        <Modal isOpen={isShowOpen} onClose={handleShowClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Incident Details</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    {
                        incidentData?.data_type === "blue"
                            ?
                            <VStack align="start" spacing={4}>
                                {/* <Text><strong>ID:</strong> {incidentData.id}</Text> */}
                                <Text><strong>কবে ঘটনা ঘটেছে?:</strong> {incidentData?.date_of_incident?.split(' ')[0] || "N/A"}</Text>

                                <Text><strong>ডাটা ধরন:</strong> {incidentData?.data_type || "N/A"}</Text>

                                <Box>
                                    <Text><strong>Complex Category:</strong></Text>
                                    <Box p={2} >
                                        {/* <Text><strong>Tag:</strong> {incidentData?.complex_category?.tag || "N/A"}</Text> */}
                                        <Text><strong>অপরাধের বিস্তৃত ধরন  :</strong> {incidentData?.complex_category?.broad_category || "N/A"}</Text>
                                        <Text>
                                            <strong>অপরাধের নির্দিষ্ট ধরন :</strong>{" "}
                                            {Array.isArray(incidentData?.complex_category?.specific_category)
                                                ? incidentData?.complex_category?.specific_category?.join(", ")
                                                : "N/A"}
                                        </Text>
                                        <Text><strong>বিস্তৃত এবং নির্দিষ্ট প্রেক্ষাপট :</strong></Text>
                                        <Text pl={4}>
                                            {formatContext(incidentData?.complex_category?.context)}
                                        </Text>
                                    </Box>
                                </Box>

                                <Text>
                                    <strong>কোথায় ঘটনা ঘটেছে?:</strong>{" "} <br />
                                    জেলা: {incidentData?.place_of_occurrence?.district || "N/A"}, <br />
                                    উপজেলা: {incidentData?.place_of_occurrence?.upazila || "N/A"}, <br />
                                    গ্রাম: {incidentData?.place_of_occurrence?.village || ""}{" "}<br />
                                    ফোন নাম্বার: {incidentData?.place_of_occurrence?.phone || ""}
                                </Text>

                                <Divider />

                                <Text><strong>ভুক্তভোগীর তথ্য:</strong></Text>
                                {incidentData?.victim_information?.map((victim, index) => (
                                    <Box key={index} p={2} borderWidth={1} borderRadius="md">
                                        <Text><strong>নাম:</strong> {victim?.name}</Text>
                                        <Text><strong>বয়স:</strong> {victim?.age}</Text>
                                        <Text><strong>লিঙ্গ:</strong> {victim?.gender}</Text>
                                        <Text><strong>পেশা:</strong> {victim?.occupation}</Text>
                                        <Text>
                                            <strong>ঠিকানা:</strong>{" "} <br />
                                            জেলা: {victim?.address?.district || "N/A"}, <br />
                                            উপজেলা: {victim?.address?.upazila || "N/A"}, <br />
                                            গ্রাম: {victim?.address?.village || ""}{" "}<br />
                                            ফোন নাম্বার: {victim?.address?.phone || ""}
                                        </Text>
                                        <Text><strong>ভুক্তভোগীর সাথে তথ্য-প্রদানকারীর সম্পর্ক:</strong> {victim?.relation_with_victim?.relation} {victim?.relation_with_victim?.extra !== 'N/A' && `(${victim?.relation_with_victim?.extra})`}</Text>
                                    </Box>
                                ))}

                                <Divider />

                                <Text><strong>যাদের বিরুদ্ধে অভিযোগ (সম্ভাব্য):</strong></Text>
                                {incidentData?.those_accused?.map((accused, index) => (
                                    <Box key={index} p={2} borderWidth={1} borderRadius="md">
                                        <Text><strong>অজ্ঞাত:</strong> {accused?.unknown === "true" ? "হ্যাঁ" : 'না'}</Text>
                                        <Text><strong>নাম:</strong> {accused?.name}</Text>
                                        <Text><strong>বয়স:</strong> {accused?.age}</Text>
                                        <Text><strong>লিঙ্গ:</strong> {accused?.gender}</Text>
                                        <Text>
                                            <strong>ঠিকানা:</strong>{" "} <br />
                                            জেলা: {accused?.address?.district || "N/A"}, <br />
                                            উপজেলা: {accused?.address?.upazila || "N/A"}, <br />
                                            গ্রাম: {accused?.address?.village || ""}{" "}<br />
                                            ফোন নাম্বার: {accused?.address?.phone || ""}
                                        </Text>
                                        <Text><strong>পেশা:</strong> {accused?.occupation}</Text>
                                        {/* <Text><strong>কোন সংস্থা/সংগঠন-এর সাথে যুক্ত?:</strong> {Array.isArray(accused?.organization) && accused?.organization?.join(', ')}</Text> */}
                                        <Text><strong>কোন সংস্থা/সংগঠন-এর সাথে যুক্ত?:</strong> {accused?.organization}</Text>
                                    </Box>
                                ))}

                                <Divider />

                                <Box>
                                    <strong>ঘটনাটি কী কোনো গণমাধ্যমে প্রকাশিত হয়েছে?:</strong>{" "}
                                    {incidentData?.in_media?.choice || "N/A"} {" "}
                                    {incidentData?.in_media?.extra?.includes("when:") && incidentData?.in_media?.extra?.includes("where:") ? (
                                        <Box>
                                            <Text>
                                                কবে:{" "}
                                                {incidentData?.in_media?.extra?.match(/when:([^,]*)/)?.[1]?.trim() || "N/A"}
                                            </Text>
                                            <Text>
                                                কোথায়:{" "}
                                                {incidentData?.in_media?.extra?.match(/where:([^,]*)/)?.[1]?.trim() || "N/A"}
                                            </Text>
                                        </Box>
                                    ) : (
                                        <span>
                                            ({incidentData?.in_media?.extra || "N/A"})
                                        </span>
                                    )}
                                </Box>

                                <Box>
                                    <strong>এই ঘটনার জন্য কোনো কর্তৃপক্ষকে জানানো হয়েছে কিনা?:</strong>{" "}
                                    {incidentData?.authorities_informed?.choice || "N/A"} {" "}
                                    {incidentData?.authorities_informed?.extra?.includes("YesDetails:") &&
                                        incidentData?.authorities_informed?.extra?.match(/YesDetails:([^,]*)/)?.[1]?.trim() ? (
                                        <span>
                                            - বিস্তারিত লিখুন - মামলা বা অভিযোগের বৃতান্ত: {incidentData?.authorities_informed?.extra?.match(/YesDetails:([^,]*)/)?.[1]?.trim()}
                                        </span>
                                    ) : incidentData?.authorities_informed?.extra?.includes("NoWhy:") &&
                                        incidentData?.authorities_informed?.extra?.match(/NoWhy:([^,]*)/)?.[1]?.trim() ? (
                                        <span>
                                            - বিস্তারিত লিখুন - কেনো?: {incidentData?.authorities_informed?.extra?.match(/NoWhy:([^,]*)/)?.[1]?.trim()}
                                        </span>
                                    ) : (
                                        <span>({incidentData?.authorities_informed?.extra || "N/A"})</span>
                                    )}
                                </Box>

                                <Text>
                                    <strong>সরকার পক্ষ থেকে কোনো প্রতিকারমূলক/আইনি পদক্ষেপ নেওয়া হয়েছে কিনা?:</strong>{" "}
                                    {incidentData?.step_by_government?.choice || "N/A"} ({incidentData?.step_by_government?.extra || "N/A"})
                                </Text>

                                <Text><strong>ঘটনার বর্ণনা :</strong> {incidentData?.incident_description}</Text>

                                <Text><strong>ঘটনার প্রমাণ:</strong> {incidentData?.files?.length}</Text>

                                <Box>
                                    <Text><strong>ঘটনা সংক্রান্ত কোন URL / লিঙ্ক থাকলে আপলোড করুন:</strong></Text>
                                    {incidentData?.links?.map((link, index) => (
                                        <Text key={index} px={1}><strong>{index + 1}:</strong> {link},</Text>
                                    ))}
                                </Box>

                                <Text>
                                    <strong>তথ্য প্রদানকারী যদি অন্য কারোর জন্য এই ঘটনা লিপিবদ্ধ করে, সেক্ষেত্রে তথ্য প্রদানকারীর পরিচয়:</strong>{" "} <br />
                                    নাম: {incidentData?.provider_info?.name || ""},<br />
                                    বয়স: {incidentData?.provider_info?.age || ""},<br />
                                    লিঙ্গ: {incidentData?.provider_info?.gender || ""}, <br />
                                    জেলা: {incidentData?.provider_info?.district || ""}, <br />
                                    উপজেলা: {incidentData?.provider_info?.upazila || ""}, <br />
                                    গ্রাম: {incidentData?.provider_info?.village || ""},<br />
                                    ফোন নাম্বার: {incidentData?.provider_info?.phone || ""},<br />
                                    পেশা: {incidentData?.provider_info?.profession || ""},
                                </Text>
                            </VStack>
                            :
                            <VStack align="start" spacing={4}>
                                {/* <Text><strong>ID:</strong> {incidentData.id}</Text> */}
                                <Text><strong>তথ্যের উৎস/নাম (গণমাধ্যম/সোশ্যাল মিডিয়া /ব্যাক্তিগত যোগাযোগ):</strong> {incidentData?.green_fifth?.information_source || "N/A"}</Text>

                                <Divider />

                                <Text><strong>তথ্য প্রকাশের তারিখ:</strong> {incidentData?.green_fifth?.publication_date?.split(' ')[0] || "N/A"}</Text>

                                <Text><strong>ঘটনা যেদিন ঘটেছে, সেটার তারিখ:</strong> {incidentData?.green_fifth?.date_of_incident?.split(' ')[0] || "N/A"}</Text>

                                <Text><strong>ডাটা ধরন:</strong> {incidentData?.data_type || "N/A"}</Text>

                                <Divider />

                                <Box>
                                    <Text><strong>Complex Category:</strong></Text>
                                    <Box p={2} >
                                        <Text><strong>ট্যাগ :</strong> {incidentData?.complex_category?.tag || "N/A"}</Text>
                                        <Text><strong>অপরাধের বিস্তৃত ধরন:</strong> {incidentData?.complex_category?.broad_category || "N/A"}</Text>
                                        <Text>
                                            <strong>অপরাধের নির্দিষ্ট ধরন:</strong>{" "}
                                            {Array.isArray(incidentData?.complex_category?.specific_category)
                                                ? incidentData?.complex_category?.specific_category.join(", ")
                                                : "N/A"}
                                        </Text>
                                        <Text><strong>বিস্তৃত এবং নির্দিষ্ট প্রেক্ষাপট :</strong></Text>
                                        <Text pl={4}>
                                            {formatContext(incidentData?.complex_category?.context)}
                                        </Text>
                                    </Box>
                                </Box>

                                <Divider />

                                <Text>
                                    <strong>ঘটনার স্থান:</strong>{" "} <br />
                                    জেলা: {incidentData?.green_fifth?.place_of_occurrence?.district || "N/A"}, <br />
                                    উপজেলা: {incidentData?.green_fifth?.place_of_occurrence?.upazila || "N/A"}, <br />
                                    গ্রাম: {incidentData?.green_fifth?.place_of_occurrence?.village || ""}{" "}<br />
                                    ফোন নাম্বার: {incidentData?.green_fifth?.place_of_occurrence?.phone || ""}
                                </Text>

                                <Text><strong>ঘটনার প্রমাণ:</strong> {incidentData?.green_fifth?.files?.length}</Text>

                                <Box>
                                    <Text><strong>রিপোর্টারের (প্রতিবেদকের?) তথ্য:</strong></Text>

                                    <Box>
                                        <Text><strong>নাম:</strong> {incidentData?.green_fifth?.reporter_info?.name}</Text>
                                        <Text><strong>ফোন নং:</strong> {incidentData?.green_fifth?.reporter_info?.phone}</Text>
                                    </Box>
                                </Box>
                            </VStack>
                    }
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" onClick={handleShowClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent >
        </Modal >
    )
}