
const categoryOfData = {
	"tags": [
		"মানবাধিকার",
		"কূটনৈতিক",
		"ইতিহাস",
		"রাজনৈতিক",
		"আইন",
	],
	"broad_category_of_offense": {
		"সহিংসতা": {
			"specific_category_of_offense": ["হত্যা", "ধর্ষণ", "অগ্নিসংযোগ", "সম্পত্তি বিনাশ", "নির্যাতন", "গুম/অপহরণ", "বিচার বহির্ভূত হত্যা", "স্থাপনা", "চুরি/ডাকাতি/লুটপাট", "অন্যান্য"],
			"broad_context(incident/victim)": ["সংখ্যালঘু", "১৯৭১/মুক্তিযুদ্ধ", "রাজনৈতিক", "আইনশৃঙ্খলা বাহিনী", "গণমাধ্যম", "বিচারব্যবস্থা", "সংস্কৃতি"],
			// "broad_context(incident/victim)": {
			"specific_context(incident/victim)": {
				"সংখ্যালঘু": {
					"ধর্ম ভিত্তিক": ["মুসলিম", "আহমাদিয়া", "সুফী/মাজার কেন্দ্রিক বিশ্বাস", "হিন্দু", "বৌদ্ধ", "খ্রিস্টান", "নাস্তিক"],
					"জাতি ভিত্তিক": ["বাঙালি", "পাহাড়ি আদিবাসী", "সমতল আদিবাসী"],
					"লিঙ্গভিত্তিক": ["LGBTQ", "নারী"],
					"অন্যান্য": ["অন্যান্য"],
				},
				"১৯৭১/মুক্তিযুদ্ধ": ["মুক্তিযোদ্ধা এবং তাঁদের পরিবার", "বীরাঙ্গনা এবং তাঁদের পরিবার", "শহীদ পরিবার", "ICT- বিচারক এবং সাক্ষী", "একটিভিস্ট/লেখক/গবেষক", "স্থাপনা/ভাস্কর্য", "আর্কাইভ / জাদুঘর", "অন্যান্য"],
				"রাজনৈতিক": ["আওয়ামিলীগ ও অঙ্গসংগঠন", "বিএনপি ও অঙ্গসংগঠন", "অন্যান্য"],
				"আইনশৃঙ্খলা বাহিনী": ["পুলিশ", "আর্মি", "আনসার", "BGB", "RAB"],
				"গণমাধ্যম": ["স্থাপনা", "সংবাদকর্মী"],
				"বিচারব্যবস্থা": ["বিচারক", "আইনজীবী", "সাক্ষী", "আসামী"],
				"সংস্কৃতি": ["স্থাপনা / ভাস্কর্য", "শিল্পী/সাহিত্যিক/কর্মী", "আর্কাইভ", "অন্যান্য"],
			},
		},
		"হুমকি/ভয়ভীতি প্রদর্শন": {
			"specific_category_of_offense": ["হত্যা", "ধর্ষণ", "অগ্নিসংযোগ", "সম্পত্তি বিনাশ", "নির্যাতন", "গুম/অপহরণ", "জোরপূর্বক চাকরীচ্যুত করা বা কর্তব্যে বাঁধা প্রদান", "মামলা", "চুরি/ডাকাতি/লুটপাট", "অন্যান্য"],
			"broad_context(incident/victim)": ["সংখ্যালঘু", "১৯৭১/মুক্তিযুদ্ধ", "রাজনৈতিক", "আইনশৃঙ্খলা বাহিনী", "গণমাধ্যম", "বিচারব্যবস্থা", "সংস্কৃতি"],
			// "broad_context(incident/victim)": {
			"specific_context(incident/victim)": {
				"সংখ্যালঘু": {
					"ধর্ম ভিত্তিক": ["মুসলিম", "আহমাদিয়া", "সুফী/মাজার কেন্দ্রিক বিশ্বাস", "হিন্দু", "বৌদ্ধ", "খ্রিস্টান", "নাস্তিক"],
					"জাতি ভিত্তিক": ["বাঙালি", "পাহাড়ি আদিবাসী", "সমতল আদিবাসী"],
					"লিঙ্গভিত্তিক": ["LGBTQ", "নারী"],
					"অন্যান্য": ["অন্যান্য"],
				},
				"১৯৭১/মুক্তিযুদ্ধ": ["মুক্তিযোদ্ধা এবং তাঁদের পরিবার", "বীরাঙ্গনা এবং তাঁদের পরিবার", "শহীদ পরিবার", "ICT- বিচারক এবং সাক্ষী", "একটিভিস্ট/লেখক/গবেষক", "স্থাপনা/ভাস্কর্য", "আর্কাইভ / জাদুঘর", "অন্যান্য"],
				"রাজনৈতিক": ["আওয়ামিলীগ ও অঙ্গসংগঠন", "বিএনপি ও অঙ্গসংগঠন", "অন্যান্য"],
				"আইনশৃঙ্খলা বাহিনী": ["পুলিশ", "আর্মি", "আনসার", "BGB", "RAB"],
				"গণমাধ্যম": ["স্থাপনা", "সংবাদকর্মী"],
				"বিচারব্যবস্থা": ["বিচারক", "আইনজীবী", "সাক্ষী", "আসামী"],
				"সংস্কৃতি": ["স্থাপনা / ভাস্কর্য", "শিল্পী/সাহিত্যিক/কর্মী", "আর্কাইভ", "অন্যান্য"],
			},
		},
		"হয়রানি/বৈষম্য": {
			"specific_category_of_offense": ["মামলা", "জোরপূর্বক চাকরীচ্যুত করা বা কর্তব্যে বাঁধা প্রদান", "সমাবেশে বাঁধা প্রদান", "ধর্মীয় আচার অনুষ্ঠান পালনে বাঁধাপ্রদান/অনধিকার চর্চা", "মত প্রকাশের স্বাধীনতা", "অন্যান্য"],
			"broad_context(incident/victim)": ["সংখ্যালঘু", "১৯৭১/মুক্তিযুদ্ধ", "রাজনৈতিক", "আইনশৃঙ্খলা বাহিনী", "গণমাধ্যম", "বিচারব্যবস্থা", "সংস্কৃতি"],
			// "broad_context(incident/victim)": {
			"specific_context(incident/victim)": {
				"সংখ্যালঘু": {
					"ধর্ম ভিত্তিক": ["মুসলিম", "আহমাদিয়া", "সুফী/মাজার কেন্দ্রিক বিশ্বাস", "হিন্দু", "বৌদ্ধ", "খ্রিস্টান", "নাস্তিক"],
					"জাতি ভিত্তিক": ["বাঙালি", "পাহাড়ি আদিবাসী", "সমতল আদিবাসী"],
					"লিঙ্গভিত্তিক": ["LGBTQ", "নারী"],
					"অন্যান্য": ["অন্যান্য"],
				},
				"১৯৭১/মুক্তিযুদ্ধ": ["মুক্তিযোদ্ধা এবং তাঁদের পরিবার", "বীরাঙ্গনা এবং তাঁদের পরিবার", "শহীদ পরিবার", "ICT- বিচারক এবং সাক্ষী", "একটিভিস্ট/লেখক/গবেষক", "অন্যান্য"],
				"রাজনৈতিক": ["আওয়ামিলীগ ও অঙ্গসংগঠন", "বিএনপি ও অঙ্গসংগঠন", "অন্যান্য"],
				"আইনশৃঙ্খলা বাহিনী": ["পুলিশ", "আর্মি", "আনসার", "BGB", "RAB"],
				"গণমাধ্যম": ["সংবাদকর্মী"],
				"বিচারব্যবস্থা": ["বিচারক", "আইনজীবী", "সাক্ষী", "আসামী"],
				"সংস্কৃতি": ["শিল্পী/সাহিত্যিক/কর্মী", "অন্যান্য"],
			},
		},
		"উস্কানি": {
			"specific_category_of_offense": ["হত্যা", "ধর্ষণ", "অগ্নিসংযোগ", "সম্পত্তি বিনাশ", "নির্যাতন", "গুম/অপহরণ", "স্থাপনা ", "মামলা", "চুরি/ডাকাতি/লুটপাট", "অন্যান্য"],
			"broad_context(incident/victim)": ["সংখ্যালঘু", "১৯৭১/মুক্তিযুদ্ধ", "রাজনৈতিক", "আইনশৃঙ্খলা বাহিনী", "গণমাধ্যম", "বিচারব্যবস্থা", "সংস্কৃতি"],
			// "broad_context(incident/victim)": {
			"specific_context(incident/victim)": {
				"সংখ্যালঘু": {
					"ধর্ম ভিত্তিক": ["মুসলিম", "আহমাদিয়া", "সুফী/মাজার কেন্দ্রিক বিশ্বাস", "হিন্দু", "বৌদ্ধ", "খ্রিস্টান", "নাস্তিক"],
					"জাতি ভিত্তিক": ["বাঙালি", "পাহাড়ি আদিবাসী", "সমতল আদিবাসী"],
					"লিঙ্গভিত্তিক": ["LGBTQ", "নারী"],
					"অন্যান্য": ["অন্যান্য"],
				},
				"১৯৭১/মুক্তিযুদ্ধ": ["মুক্তিযোদ্ধা এবং তাঁদের পরিবার", "বীরাঙ্গনা এবং তাঁদের পরিবার", "শহীদ পরিবার", "ICT- বিচারক এবং সাক্ষী", "একটিভিস্ট/লেখক/গবেষক", "অন্যান্য"],
				"রাজনৈতিক": ["আওয়ামিলীগ ও অঙ্গসংগঠন", "বিএনপি ও অঙ্গসংগঠন", "অন্যান্য"],
				"আইনশৃঙ্খলা বাহিনী": ["পুলিশ", "আর্মি", "আনসার", "BGB", "RAB"],
				"গণমাধ্যম": ["স্থাপনা", "সংবাদকর্মী"],
				"বিচারব্যবস্থা": ["বিচারক", "আইনজীবী", "সাক্ষী", "আসামী"],
				"সংস্কৃতি": ["স্থাপনা/ভাস্কর্য", "শিল্পী/সাহিত্যিক/কর্মী", "আর্কাইভ", "অন্যান্য"],
			},
		},
	}
};

export default categoryOfData;

